import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer6'
// import Partner from '../layout/partner'
// import Testimonial1 from '../element/testimonial1';

const bg = require('../../images/banner/banner1.png')
// const bg2 = require('../../images/background/bg1.jpg')
// const bg3 = require('../../images/background/bg1.jpg')

class Newsmflf extends Component {

    render() {
        return (
            <>
                <Header/>
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">News</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/newsall">News</Link></li>
                                        <li>January 26th 2023</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                            <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-head text-center">

                                    <h2 className="title">"ENSERV" and "Mae Fah Luang (Doi Tung)" Signing MoU Ceremony</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-content row">
                                    <div className="col-md-12 col-lg-12 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                        <div className="icon-bx-wraper" data-name="01">
                                            <div className="icon-content">
                                                <div className="section-content row">
                                                    <div className="col-lg-6 col-md-12">
                                                        <img src={require("../../images/news/mflf/IMG-21.jpg")} alt="center"/>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <img src={require("../../images/news/mflf/IMG-316.jpg")} alt="center"/>
                                                    </div>
                                                </div>
                                                <br/>
                                                <h2 className="title-ourservice">January 26th 2023</h2>
                                                    <p align="justify">On January 26th, 2023, at the "Mae Fah Luang Foundation under Royal Patronage", headquarters Bangkok, was held the signing of the Memorandum of Understanding (MoU) ceremony between "Mae Fah Luang Foundation under Royal Patronage" (MFLF) by M.L. Dispanadda Diskul, Chief Executive Officer, and “ENSERV Holding Co., Ltd.,” led by Mr. Thanachat Pochana, ENSERV's chairman.<br/><br/></p>
                                                <div className="section-content row">
                                                    <div className="col-lg-6 col-md-12">
                                                        <img src={require("../../images/news/mflf/IMG-420.jpg")} alt="center"/>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <img src={require("../../images/news/mflf/IMG-461.jpg")} alt="center"/>
                                                    </div>
                                                </div>

                                                <br/>
                                                <p align="justify">Since "ENSERV" has developed products for consumption under the "ENSERV M Food” project or “EMF", which responds to the goal of researching and developing food innovations that will make the world's population healthier. At the same time, ENSERV's goal is to develop products that are in line with the Sustainable Development Goals (SDGs), Bio-Economic Circular Economy and Green Economy (Bio-Circular Green Economy), including macro development. <br/><br/>
                                                This is considered a holistic development from upstream to downstream. By focusing on the development of personal potential, the smallest but the most important cog can bring further development at a larger community.<br/><br/>
                                                However, ENSERV's product development goals it is also in line with the business practices of "MFLF", which mainly focuses on the development of the potential of "people", as can be seen from "MFLF" skills and career training for villagers furthering local wisdom as well as promoting business processes by supporting the community to generate income from social activities in 5 categories: handicrafts, Doi Tung cafe, tourism, food processing and agriculture.<br/><br/>
                                                The vision of "ENSERV" that wants to improve the quality of life of the community.  Therefore, in cooperation with the “MFLF” to improve villagers' skills in processing upstream ingredients such as rice and bananas. Together with increasing the ability to produce quality products to be seen in the eyes of the world. It helps people in communities to earn a living and better future.</p>
                                            </div>
                                         </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <Footer/>
            </>
        )
    }
}
export default Newsmflf;