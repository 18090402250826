import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from '../layout/header1';
import Footer from '../layout/footer';

const bg = require('../../images/banner/bnharvesting.png')

class EnsInnoScm extends Component {

    render() {


        return (
            <>
                <Header />
                    <div className="page-content bg-white">
                        <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                            <div className="container">
                                <div className="dlab-bnr-inr-entry">
                                    <h1 className="text-white">SUPER CARBON MATERIALS</h1>
                                    <div className="breadcrumb-row">
                                        <ul className="list-inline">
                                            <li><Link to="/">HOME</Link></li>
                                            <li><Link to ="ourproduct">OUR PROJECT</Link></li>
                                            <li><Link to ="ensinnoslider">ENERGY INNOVATION</Link></li>
                                            <li><Link to ="smd">SUSTAINABLE MATERIALS DEVERLOPMENT</Link></li>
                                            <li>SUPER CARBON MATERIALS</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section-full bg-gray content-inner" img src={require("../../images/supercarbonmaterials.png")} alt="">
                            <div className="container">
                                <div className="section-content row">
                                    <div className="col-lg-8 col-md-12">
                                        <h1 className="title align-items-right tocreate">Super Carbon Materials</h1>
                                        <p>A next generation pure carbon supercharged with graphene. Born out of a need for a higher performance activated carbon, ENSERV R&D has created a world-first nano-graphitic carbon. ENSERV Nano-Graphitic Carbon™ is sustainably sourced from agricultural products and delivers a 20-40% performance boost in conventional capacitors and will be the key enabling technology of next-generation supercapacitors. Beyond energy storage, Nano-Graphitic Carbon™ has a multitude of uses from air and water filtration, composites, and automotive.</p>
                                        <Link to="ensinnobe" className="site-button appointment-btn btnhover13">Learn More</Link>
                                    </div>

                                    <div className="col-lg-4 col-md-12">
                                        <img src={require("../../images/supercarbonmaterials.png")} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <Footer />
            </>
        )
    }
}
export default EnsInnoScm;