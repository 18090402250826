import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';

const Footer = ()=>{
	const form = useRef();
	const sendEmail = (e) => {
		e.preventDefault();
		//emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_USER_ID')
		emailjs.sendForm('gmail', 'YOUR_TEMPLATE_ID', e.target, 'd9b2e0f5fc72cb94792110e8ff2028f3-us16')
		  .then((result) => {
			  console.log(result.text);
		  }, (error) => {
			  console.log(error.text);
		  });
		  e.target.reset()
	 };

	return (
		<>

			<footer className="site-footer style1">
				{/* <div className="section-full p-t50 p-b20 bg-primary text-white overlay-primary-dark footer-info-bar">
					<div className="container">
						<div className="row">
							<div className="col-lg-3 col-md-6 col-sm-6 m-b30">
								<div className="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1">
									<div className="icon-content">
										<h5 className="dlab-tilte">
											<span className="icon-sm"><i className="ti-location-pin"></i></span>
											Company Address
										</h5>
										<p className="op7">1339 Pracharad 1 Rd., Wongsawang, Bangsue, Bangkok 10800 Thailand</p>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6 m-b30">
								<div className="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1">
									<div className="icon-content">
										<h5 className="dlab-tilte">
											<span className="icon-sm"><i className="ti-email"></i></span>
											E-mail
										</h5>
										<p className="m-b0 op7">info@enserv.co.th</p>
										<br/>
										<br/>

										<p className="op7">company@example.com</p>
									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6 m-b30">
								<div className="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1">
									<div className="icon-content">
										<h5 className="dlab-tilte">
											<span className="icon-sm"><i className="ti-mobile"></i></span>
											Phone Numbers
										</h5>

										<p className="op7">Phone : +66 2587 4751</p>
										<br/>
										<br/>

									</div>
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6 m-b30">
								<div className="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1">
									<div className="icon-content">
										<h5 className="dlab-tilte">
											<span className="icon-sm"><i className="ti-alarm-clock"></i></span>
											Office Hours
										</h5>
										<p className="m-b0 op7">Mon To Fri - 08.30-17.30</p>
										<p className="op7">Sat To Sun - Close</p>
										<br/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> */}


				<div className="footer-top">
					<div className="container">
						<div className="row">
							<div className="col-md-4 col-sm-6">
								<div className="widget widget_about">
									<h4 className="footer-title">About Company</h4>
									<p>“Create the World’s happiness and better livings with sustainability for human being”</p>
									<Link to={"/about-1"} className="readmore" target="_top">Read More</Link>
								</div>
							</div>
							<div className="col-md-4 col-sm-6">
								<div className="widget">
									<h4 className="footer-title">Company</h4>
									<ul className="list-2">
										<li><Link to={"/about-1"}>About Us</Link></li>
										<li><Link to={"/ourproduct"}>Our Project</Link></li>
										<li><Link to={"/privacy-policy"}>Privacy Policy</Link></li>
										<li><Link to={"/ourproduct"}>Our Business</Link></li>
										<li><Link to={"/contact-4"}>Contact</Link></li>
										{/* <li><a href={"https://ssl.jobcan.jp/login/pc-employee-global?lang_code=th"} target={"_blank"}>Enserv Portal</a></li> */}
									</ul>
								</div>
							</div>
							<div className="col-md-4 col-sm-6">
								<div className="widget widget_subscribe">
									<h4 className="footer-title">Our Business</h4>
									<p>If you have any questions. Subscribe to our newsletter to get our latest products.</p>
									<form className="dzSubscribe" ref={form} onSubmit={sendEmail} >
										<div className="dzSubscribeMsg"></div>
										<div className="form-group">
											<div className="input-group">
												<input name="dzEmail" required="required" type="email" className="form-control" placeholder="Your Email Address"/>
												<div className="input-group-addon">
													<button name="submit" value="Submit" type="submit" className="site-button fa fa-paper-plane-o"></button>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="footer-bottom footer-line">
					<div className="container">
						<div className="footer-bottom-in">
							<p>© 2022 Enserv holding Co.,Ltd. All rights reserved.</p>
							<div className="footer-bottom-social">
								<ul className="dlab-social-icon dez-border">
									<li><Link className="fa fa-facebook" to={"https://www.facebook.com/enservthailand"}></Link></li>
									<li><Link className="fa fa-twitter" to={"https://twitter.com/home"}></Link></li>
									<li><Link className="fa fa-youtue" to={"/#"}></Link></li>
									<li><Link className="fa fa-wechat" to={"/#"}></Link></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
}

export default Footer;