import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PopNews from './popnews';

function PopUp(args) {
  const [modal, setModal] = useState(true);

  const toggle = () => setModal(!modal);

  return (
    <div>
      {/* <Button color="danger" onClick={toggle}>
        Click Me
      </Button> */}
      <Modal isOpen={modal} {...args}>
        {/* <ModalHeader toggle={toggle}>Modal title</ModalHeader> */}
        <ModalBody modal-xlg>
          <PopNews />
        </ModalBody>

        <ModalFooter>
          {/* <Button onClick={toggle}>

            <Link to={'/'}>Welcome to Enserv</Link>
          </Button>{' '} */}
          <Button color="secondary" onClick={toggle}>
            Welcome to Enserv
          </Button>
        </ModalFooter>

      </Modal>
    </div>
  );
}

export default PopUp;