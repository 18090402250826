import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from '../layout/header';
import Footer from '../layout/footer6';

const bg = require('../../images/banner/lithiumsulphurbattery.png')



class EnsinnoSodiumSlider extends Component {

    render() {

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true
        };

        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">SODIUM ION BATTERY</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">HOME</Link></li>
                                        <li><Link to="ourproduct">OUR PROJECTS</Link></li>
                                        <li><Link to="ensinnoslider">ENERGY INNOVATION</Link></li>
                                        <li><Link to="es">ENERGY STORAGE</Link></li>
                                        <li>SODIUM ION BATTERY</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full content-inner">
                        <div className="container">
                            <div className='section-content-row'>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className='blog-post blog-grid blog-rounded blog-effect1 fly-box'>
                                            <img src={require("../../images/energyinnovation/energy_sodium1.png")} alt="" />
                                        </div>
                                        <p><a href={"https://spectrum.ieee.org/a-first-prototype-of-a-sodiumion-rechargeable-battery"} target={"_blank"}>https://spectrum.ieee.org/a-first-prototype-of-a-sodiumion-rechargeable-battery</a></p>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className="dlab-post-text">
                                            <blockquote><p align="justify">Sodium is abundant, less expensive and more environmentally friendly than lithium. Compared to Li-S battery,
                                                sodium ion battery is highly stable and longer in life cycle, but limited in performance and energy density. Our
                                                researchers are intending to refine its design, enhance its performance and integrate an outstanding technology
                                                to be completely toxic-free and recyclable. Uniquely, ENSERV's Sodium ion battery is anode-free, which absolutely
                                                lighter and smaller, suitable for light-duty EVs and grid energy storage.
                                            </p>
                                            </blockquote>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <Footer />
            </>
        )
    }
}
export default EnsinnoSodiumSlider;