import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from '../layout/header';
import Footer from '../layout/footer6';

const bg = require('../../images/banner/banner1.png')




class EnergyStorage extends Component {

    render() {
        return (
            <>

                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">ENERGY STORAGE</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">HOME</Link></li>
                                        <li><Link to="ourproduct">OUR PROJECTS</Link></li>
                                        <li><Link to="ensinnoslider">ENERGY INNOVATION</Link></li>
                                        <li>ENERGY STORAGE</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full content-inner bg-white">
                        <div className="container">
                            <h2>Step into the future of sustainable power solutions.</h2>
                            <p align="justify">Explore our inventive technologies that redefine the way we store energy, ensuring reliability and efficiency in an ever-evolving energy ecosystem.</p>
                            <div className="row center">
                                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="/ensinnolsbslider"><img src={require("../../images/energyinnovation/energy_litium_sulfur.jpg")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">
                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="/ensinnolsbslider">LITHIUM SULPHUR BATTERY</Link></h4>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>A more powerful, cheaper, and greener battery for the future.</p>
                                            </div>
                                            <div className="dlab-post-readmore">
                                                <Link to="/ensinnolsbslider" title="READ MORE" rel="bookmark" className="site-button btnhover16" target="_top">READ MORE
                                                    <i className="ti-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="/ensinnosodiumslider"><img src={require("../../images/energyinnovation/energy_sodium.jpg")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">

                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="/ensinnosodiumslider">SODIUM ION BATTERY</Link></h4>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>Innovative new battery that is environmentally friendly and meets comprehensive usage requirements. Including the production cost is cheap.</p>
                                            </div>
                                            <div className="dlab-post-readmore">
                                                <Link to="/ensinnosodiumslider" title="READ MORE" rel="bookmark" className="site-button btnhover16" target="_top">READ MORE
                                                    <i className="ti-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="/ensinnoultracapaslider"><img src={require("../../images/energyinnovation/energy_supercapacitors.jpg")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">

                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="/ensinnoultracapaslider">SUPERCAPACITORS</Link></h4>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>The project is dedicated to the development of asymmetric supercapacitors based on the newly discovered polymorph of manganese dioxide (MnOx) into an industrially viable energy storage solution.</p>
                                            </div>
                                            <div className="dlab-post-readmore">
                                                <Link to="/ensinnoultracapaslider" title="READ MORE" rel="bookmark" className="site-button btnhover16" target="_top">READ MORE
                                                    <i className="ti-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="/ensinnobess"><img src={require("../../images/energyinnovation/energy_battery.jpg")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">

                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="/ensinnobess">BATTERY ENERGY STORAGE SYSTEM</Link></h4>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>Enabling a zero-carbon grid when the wind isn’t blowing, and the sun isn’t shining.</p>
                                            </div>
                                            <div className="dlab-post-readmore">
                                                <Link to="/ensinnobess" title="READ MORE" rel="bookmark" className="site-button btnhover16" target="_top">READ MORE
                                                    <i className="ti-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />

            </>
        )
    }
}
export default EnergyStorage;