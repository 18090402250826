import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header';
import Footer from '../layout/footer6';


const bg = require('../../images/banner/banner1.png')


class Sustainability extends Component {

    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">SUSTAINABILITY</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>Sustainability</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="section-full content-inner">
                        <div className="container">
                            <div className="section-head text-center">

                                <div className='section-content row'>
                                    <div className='col-md-4 col-lg-6 col-sm-12'>
                                        <img src={require("../../images/demo/demo2.jpg")} alt="" />
                                    </div>
                                    <div className='col-md-8 col-lg-6 col-sm-12'>
                                        <h2 className='cop26'> ENSERV’s approach to Sustainability</h2>
                                        <blockquote>
                                            <h4>At ENSERV, sustainability is at the core of our mission: Create the World’s happiness and better livings with sustainability for human being</h4>
                                        </blockquote>
                                    </div>
                                </div>
                            </div>
                            <div className="section-full call-action bg-primary wow fadeIn" data-wow-duration="2s" data-wow-delay="0.3s">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-9 text-white">
                                            <h2 className="title">Join Us Today! </h2>
                                        </div>
                                        <div className="col-lg-3 d-flex">
                                            <Link to="/contact-4" className="site-button btnhover15 white align-self-center outline ml-auto radius-xl outline-2">Contact Us </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <br />
                            <h4>Our sustainable approach to our products and activities Our perspective is that a sustainable and happy humanity is based on five pillars:</h4>
                            <p align="justify">Our perspective is that a sustainable and happy humanity is based on five pillars:</p>
                            <div className="section-content row" align="center">
                                <div className="col-md-4 col-lg-4 col-sm-12 blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                    <div className="icon-bx-wraper" data-name="01">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <img src={require("../../images/sus/cleanenergies.jpg")} alt="" />
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">
                                            <br />
                                            <h4>Build the Path for the Future of Clean Energies:</h4>
                                            <p align="justify">by developing reliable, clean, accessible and affordable energy solutions, including innovative and efficient energy harvesting and energy storage systems.</p>
                                            <br />
                                            <br />
                                            <br />
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-12 blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                    <div className="icon-bx-wraper" data-name="02">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <img src={require("../../images/sus/sorghum.jpg")} alt="" />
                                        </div>
                                        <br />
                                        <div className="dlab-info p-a20 border-1 bg-white">
                                            <h4>Accelerate Carbon Sequestration: </h4>
                                            <p align="justify">we develop innovative solutions to mitigate the climate urgency, from new industrial technologies for carbon capture, passing through cutting-edge carbon storing materials, to revolutionary crops that contribute to the creation of carbon sinks through agroforestry. Our ambition is to be a fully carbon neutral company.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-12 blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                    <div className="icon-bx-wraper" data-name="03">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <img src={require("../../images/sus/healthlife.jpg")} alt="" />
                                        </div>
                                        <br />
                                        <div className="dlab-info p-a20 border-1 bg-white">
                                            <h4>Create Healthy-lives and Well-being for all: </h4>
                                            <p align="justify">at ENSERV we believe that health is the base for happiness. This is why we not only develop innovative products like super food and health monitoring systems that directly improve human livelihood, but also take into consideration how to reduce the negative impact of our technologies on health.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-12 blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                    <div className="icon-bx-wraper" data-name="04">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <img src={require("../../images/sus/banana.jpg")} alt="" />
                                        </div>
                                        <br />
                                        <div className="dlab-info p-a20 border-1 bg-white">
                                            <h4>Promote the circular use of resources along our product value chains:  </h4>
                                            <p align="justify">the optimization of resources is at the heart of our innovation strategies, by creating synergies among our projects and along the value chain: one product is capable of generating multiple byproducts thanks to our circular approach to innovation, which benefits all stakeholders along the value chain.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-12 blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                    <div className="icon-bx-wraper" data-name="05">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <img src={require("../../images/sus/innohealth.jpg")} alt="" />
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">
                                            <h4>Accelerate Disruptive Innovations through Partnerships:</h4>
                                            <p align="justify">our Research and Development projects (R&D) count with the collaboration of over 100 in-house scientists, engineers and technicians who are continuously working with research and innovation institutions from around the world to find the best disruptive solutions for sustainable human lives.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <br />
                            <h4>Our sustainable approach to business and corporate behavior </h4>
                            <p align="justify">ENSERV is committed to sustainability and addressing ESG issues. This is why since February 2023 we are developing a comprehensive sustainability program that will allow us to establish, measure and report our own sustainability goals, performance and impacts, following the highest international ESG standards. Our ambition is to implement this sustainability program transversally at all levels of our organization.</p>
                            <br />
                            <br />
                            <h4>Our contribution to the United Nations Sustainable Development Goals (SDGs)</h4>
                            <p align="justify">We are also making sure that all of our business and activities are aligned with the SDGs in the respect of planetary boundaries. Indeed,  ENSERV is indirectly addressing nearly all 17 SDGs, and has a direct focus on the following goals that most directly align with our business at the target level:</p>
                            <br />

                        </div>
                    </div>

                    <div className="section-full bg-white content-inner-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                                    <div className="icon-bx-wraper sr-iconbox style2">
                                        <div className="icon-lg m-b30" align="center">
                                            <img align="center" src={require('../../images/sus/goal2.gif')} alt="loading..." />
                                        </div>
                                        <div className="icon-content">
                                            <h4 className="dlab-tilte">Goal 2. To achieve food security, improved nutrients and promote sustainable agriculture</h4>
                                            <p>✅Related projects: Super-Sorghum, Super-Food (EMF) - Food Ecology, Biofertilizers, Bioenhancers, Good Agriculture Practices for Sustainability (GAPS)</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
                                    <div className="icon-bx-wraper sr-iconbox style2">
                                        <div className="icon-lg m-b10">
                                            <img align="center" src={require('../../images/sus/goal3.gif')} alt="loading..." />
                                        </div>
                                        <div className="icon-content">
                                            <h4 className="dlab-tilte">Goal 3. To ensure healthy lives and promote well-being for all</h4>
                                            <p>✅Related projects: EMF, ENCARE, Telemedicine</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.9s">
                                    <div className="icon-bx-wraper sr-iconbox style2">
                                        <div className="icon-lg m-b10">
                                            <img align="center" src={require('../../images/sus/goal7.gif')} alt="loading..." />
                                        </div>
                                        <div className="icon-content">
                                            <h4 className="dlab-tilte">Goal 7. To ensure access to affordable, reliable, sustainable and innovative energy for all</h4>
                                            <p>✅Related projects: Renewable energy harvesting, bio-fuels, innovative energy storage systems, energy enhancement, smart platforms for energy efficiency</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                                    <div className="icon-bx-wraper sr-iconbox style2">
                                        <div className="icon-lg m-b10">
                                            <img align="center" src={require('../../images/sus/goal9.gif')} alt="loading..." />
                                        </div>
                                        <div className="icon-content">
                                            <h4 className="dlab-tilte">Goal 9. To build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation</h4>
                                            <p>✅Related projects: all projects and activities</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
                                    <div className="icon-bx-wraper sr-iconbox style2">
                                        <div className="icon-lg m-b10">
                                            <img align="center" src={require('../../images/sus/goal12.gif')} alt="loading..." />
                                        </div>
                                        <div className="icon-content">
                                            <h4 className="dlab-tilte">Goal 12. To ensure sustainable consumption and production patterns</h4>
                                            <p>✅Related projects: OPT, EMF, Bio-based energy storage, biomaterials</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.9s">
                                    <div className="icon-bx-wraper sr-iconbox style2">
                                        <div className="icon-lg m-b10">
                                            <img align="center" src={require('../../images/sus/goal13.gif')} alt="loading..." />
                                        </div>
                                        <div className="icon-content">
                                            <h4 className="dlab-tilte">Goal 13. To take urgent action to combat climate change and its impacts</h4>
                                            <p>✅Related projects: Carbon capture through Super-Sorghum, all projects that contribute to accelerate the transition towards renewable energy</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.9s">
                                    <div className="icon-bx-wraper sr-iconbox style2">
                                        <div className="icon-lg m-b10">
                                            <img align="center" src={require('../../images/sus/goal17.gif')} alt="loading..." />
                                        </div>
                                        <div className="icon-content">
                                            <h4 className="dlab-tilte">Goal 17. To strengthen the means of implementation and revitalize the global partnership for sustainable development</h4>
                                            <p>✅Related projects: all R&D activities, Experience and Excellency Center(s) </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default Sustainability;