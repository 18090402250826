import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from '../layout/header1';
import Footer from '../layout/footer';
import SlideEnerInnoSib1 from './slideenerinnosib1';
import SlideEnerInnoSib2 from './slideenerinnosib2';

const bg = require('../../images/banner/lithiumsulphurbattery.png')

const content = [
    {
        page: <SlideEnerInnoSib1/>
        
    },
    {
        page: <SlideEnerInnoSib2/>
    }
]


class EnsInnoSibSlider extends Component {

    render() {

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true
        };
        return (
            <>
                <Header />
                    <div className="page-content bg-white">
                        <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                            <div className="container">
                                <div className="dlab-bnr-inr-entry">
                                    <h1 className="text-white">SODIUM ION BATTERY</h1>
                                    <div className="breadcrumb-row">
                                        <ul className="list-inline">
                                            <li><Link to="/">HOME</Link></li>
                                            <li><Link to ="ourproduct">OUR PROJECT</Link></li>
                                            <li><Link to ="ensinnoslider">ENERGY INNOVATION</Link></li>
                                            <li><Link to ="es">ENERGY STORAGE</Link></li>
                                            <li>SODIUM ION BATTERY</li>
                                           
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <Slider {...settings} className="testimonial-box-carousel slider-bnr">
                                {content.map((item, id) => (
                                    <p>{item.page}</p>
                                ))}
                            </Slider>
                        </div>
                    </div>
                <Footer />
            </>
        )
    }
}
export default EnsInnoSibSlider;