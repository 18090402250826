import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer6'
// import Partner from '../layout/partner'
// import Testimonial1 from '../element/testimonial1';

const bg = require('../../images/banner/banner1.png')
// const bg2 = require('../../images/background/bg1.jpg')
// const bg3 = require('../../images/background/bg1.jpg')

class Korean extends Component {

    render() {
        return (
            <>
                <Header/>
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">News</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/newsall">News</Link></li>
                                        <li>8th February 2023</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                            <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-head text-center">

                                    <h2 className="title">Warmly Welcome S.Korean Ambassador to Visit "ENSERV"</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-content row">
                                    <div className="col-md-12 col-lg-12 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                        <div className="icon-bx-wraper" data-name="01">
                                            <div className="icon-content">
                                                <div className="section-content row">
                                                    <div className="col-lg-6 col-md-12">
                                                        <img src={require("../../images/news/korean/korean1.JPG")} alt="center"/>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <img src={require("../../images/news/korean/korean2.JPG")} alt="center"/>
                                                    </div>

                                                </div>

                                                <h2 className="title-ourservice">8th February 2023</h2>
                                                <p align="justify">On 8th February 2023 at "ENSERV" headquarters located on 24th floor of "CAT Tower" Bangrak Bangkok.  "ENSERV" led by Mr.Thanachat Pochana, Chairman and executives are honoured to welcome the Ambassador of the Republic of Korea, H.E. Mr. Moon Seoung Hyun, Ms. Kim Sung Eun, Counsellor, and Ms. Lee Jeong Ju, Commercial Attache.<br/><br/>

"ENSERV" is pleasure to present our superb innovative projects for sustainable living, such as, solar farms,  Li-ion battery, and our most remarkable "ENSERV M Food or EMF".  "EMF" is our special flour attributes "high carb, low calorie" flour, which is the main ingredient of "ENSERV"delicious menus.  ENSERV’s EMF dishes for example, EMF bread, EMF carbonara pasta, and EMF cake. are served for H.E. Mr. Moon and the Delegations.<br/><br/>

Besides, other "ENSERV" innovative projects regarding the possibility of collaboration in exporting "EMF" flour mix or ready-to-eat bakery line to Korea, setting up operating subsidy in Korea, assisting in finding potential investors for battery business, further cooperation in bilateral trade and business collaboration between "ENSERV" and Korean companies, etc., are raised in the discussion, as well.</p>


                                                {/* <div className="section-content row">
                                                    <div className="col-lg-6 col-md-12">
                                                        <img src={require("../../images/news/emfservedinapec2022/301100.jpg")} alt="center"/>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <img src={require("../../images/news/emfservedinapec2022/300707.jpg")} alt="center"/>
                                                    </div>
                                                </div> */}
                                                <br/>
                                                {/* <div className="section-content row">
                                                    <div className="col-lg-6 col-md-12">
                                                        <img src={require("../../images/news/emfservedinapec2022/301132.jpg")} alt="center"/>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <img src={require("../../images/news/emfservedinapec2022/2142.jpg")} alt="center"/>
                                                    </div>

                                                </div> */}




                                            </div>
                                         </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <Footer/>
            </>
        )
    }
}
export default Korean;