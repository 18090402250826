import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header1'
import Footer from '../layout/footer'

const bg = require('../../images/banner/bnr5.jpg')

class Ourbusiness extends Component {


    render() {
        return (
            <>
                <Header/>
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">Our Business</h1>
                                <div className="breadcrumb-row">
                                <ul className="list-inline">
                                    <li><Link to="/">Home</Link></li>
                                    <li>Ourbusiness</li>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-content row">
                                    <div className="col-md-6 col-lg-6 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                        <div className="icon-bx-wraper" data-name="01">
                                            <div className="icon-content">
                                                <h2 className="title-pillar" align="center">PILLAR 1</h2>
                                                <p>World survives and step back from its breaking boundaries. <br/><br/>
                                                Limit the tempreature increase to 1.5 C following Paris Agreement.<br/><br/>
                                                Zero Carbon and Greenhouse Gases emission.<br/><br/>
                                                100% Renewable Energy.<br/><br/>
                                                Controlled and Natural Agriculture.<br/><br/>
                                                Sustainable Materials.
                                                </p>
                                                <Link to="/ourbusiness-rei-eh-prop" className='text-btnlearnmore'>Learn More</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">
                                        <div className="icon-bx-wraper" data-name="02">
                                            <div className="icon-content">
                                                <h2 className="title-pillar" align="center">PILLAR 2</h2>
                                                <p>Human being lives happily in this world.<br/><br/>
                                                Enchance good health and balance diet.<br/><br/>
                                                Life security.</p>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <Link to="#" className='text-btnlearnmore'>Learn More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    {/* <div className="content-block">
                        <div className="section-full content-inner">
                            <div className="container">
                                <img src={require("../../images/Imagewinds.png")} alt=""/>
                                    <div className="hero-content">
                                        <br/>
                                        <h1 className="title align-items-center">Renewable Energy Innovation</h1>
                                        <div className="description">Leading in sustainable renewable energy innovation.</div>
                                    </div>
                                    <br/>
                                    <div className="contact-right">
                                        <Link to="ourbusiness-rei" className="site-button appointment-btn btnhover13">Learn More</Link>
                                    </div>
                                    <br/>
                                    <br/>
                                    <br/>
                                <img src={require("../../images/Imagefactory.png")} alt=""/>
                                    <div className="hero-content">
                                        <br/>
                                        <h1 className="title align-items-center">Renewable Energy Developer</h1>
                                    <div className="description">Leading in innovative renewable energy developer in the world.</div>
                                    </div>
                                    <br/>
                                    <div className="contact-right">
                                        <Link to="ourbusiness-red" className="site-button appointment-btn btnhover13">Learn More</Link>
                                    </div>
                                    <br/>
                                    <br/>
                                    <br/>
                                <img src={require("../../images/Imagehealth.png")} alt=""/>
                                    <div className="hero-content">
                                        <br/>
                                        <h1 className="title align-items-center">Health & Life Solutions</h1>
                                    <div className="description">One of the leading reliable and trustable health & life security solutions.</div>
                                    </div>
                                    <br/>
                                    <div className="contact-right">
                                        <Link to="ourbusiness-hls" className="site-button appointment-btn btnhover13">Learn More</Link>
                                    </div>
                                    <br/>
                                    <br/>
                                <img src={require("../../images/enpark1.png")} alt=""/>
                                    <div className="hero-content">
                                        <br/>
                                        <h1 className="title align-items-center">Edutainment Center</h1>
                                    <div className="description">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                                    </div>
                                    <br/>
                                    <div className="contact-right">
                                        <Link to="ourbusiness-enpa" className="site-button appointment-btn btnhover13">Learn More</Link>
                                    </div>
                                    <br/>
                                    <br/>
                            </div>
                        </div>
                    </div>                 */}
                <Footer/>                
                </div>
            </>
        )
    }
}
export default Ourbusiness;