import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from '../layout/header';
import Footer from '../layout/footer6';

const bg = require('../../images/banner/banner1.png')

class EnsInnoSustainableMaterials extends Component {

render() {
        return (
            <>
                <Header />
                    <div className="page-content bg-white">
                        <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                            <div className="container">
                                <div className="dlab-bnr-inr-entry">
                                    <h1 className="text-white">SUSTAINABLE MATERIALS DEVERLOPMENT</h1>
                                    <div className="breadcrumb-row">
                                        <ul className="list-inline">
                                            <li><Link to ="/">HOME</Link></li>
                                            <li><Link to ="ourproduct">OUR PROJECT</Link></li>
                                            <li><Link to ="ensinnoslider">ENERGY INNOVATION</Link></li>
                                            <li>SUSTAINABLE MATERIALS DEVERLOPMENT</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-content row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="hero-content">
                                            <h1 className="title align-items-center tocreate">Super Carbon Materials</h1>
                                            <p>Plant-derived sugars are scientifically processed to yield naturally-derived carbon powder.</p>

                                            <br/>
                                            <Link to="ensinnoscm" className="site-button appointment-btn btnhover13">Learn More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-content row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="hero-content">
                                            <h1 className="title align-items-center tocreate">Advance Electrolyte Materials</h1>
                                            <p align="justify">New organic material forNext-generation electrochemical energy storage, such as electrolyte solvents, lithium Salt, SEI formation additive, redox-acting organic electrode. and polymer materials.</p>

                                            <br/>
                                            <Link to="#" className="site-button appointment-btn btnhover13">Learn More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-content row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="hero-content">
                                            <h1 className="title align-items-center tocreate">Biodegradable Plastic Materials</h1>
                                            <p align="justify">The degradation rate of this plastic bag is fastergeneral plastic bags.</p>

                                            <br/>
                                            <Link to="ensinnohbsslider" className="site-button appointment-btn btnhover13">Learn More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <Footer />
            </>
        )
    }
}
export default EnsInnoSustainableMaterials;