import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from '../layout/header';
import Footer from '../layout/footer6';

const bg = require('../../images/banner/banner1.png')

class EnergyHarvesting extends Component {

    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">ENERGY HARVESTING</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">HOME</Link></li>
                                        <li><Link to="ourproduct">OUR PROJECTS</Link></li>
                                        <li><Link to="ensinnoslider">ENERGY INNOVATION</Link></li>
                                        <li>ENERGY HARVESTING</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-full bg-gray content-inner">
                        <div className="container">
                            <h2>Immerse yourself in the evolution of energy utilization.</h2>
                            <p align="justify">Discover our inventive harvesting technologies that capture ambient energy, leading the charge towards a more sustainable and eco-friendly approach to power generation.</p>

                            <div className="row">
                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="/ourbusiness-rei-eh-wind"><img src={require("../../images/energyinnovation/energy_wind.jpg")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">
                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="/ourbusiness-rei-eh-wind">WIND TURBINE</Link></h4>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>ENSERVs novel design of turbine blades, the efficiency was recorded up to 75% breaking the World’s record of 60% efficiency.</p>
                                            </div>
                                            <br />
                                            <div className="dlab-post-readmore">
                                                <Link to="/ourbusiness-rei-eh-wind" title="READ MORE" rel="bookmark" className="site-button btnhover16" target="_top">READ MORE
                                                    <i className="ti-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="/ourbusiness-rei-eh-prop"><img src={require("../../images/energyinnovation/energy_hydro.jpg")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">
                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="/ourbusiness-rei-eh-prop">HYDRO TURBINE</Link></h4>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>Hydro turbines are devices used in hydroelectric generation plants that transfer the energy from moving water to a rotating shaft to generate electricity.</p>
                                            </div>
                                            <div className="dlab-post-readmore">
                                                <Link to="/ourbusiness-rei-eh-prop" title="READ MORE" rel="bookmark" className="site-button btnhover16" target="_top">READ MORE
                                                    <i className="ti-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="/ensinnobuoyancy"><img src={require("../../images/energyinnovation/energy_buoyanccy.png")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">
                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="/ensinnobuoyancy">BUOYANCY AND VELOCITY FLUX</Link></h4>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>Extraction of energy from chained buoys in a high-water tank travelling up due to buoyancy force and down due to gravity is transformed...</p>
                                            </div>
                                            <div className="dlab-post-readmore">
                                                <Link to="/ensinnobuoyancy" title="READ MORE" rel="bookmark" className="site-button btnhover16 " target="_top">READ MORE
                                                    <i className="ti-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="/ensinnocoldfusion"><img src={require("../../images/energyinnovation/energy_coldfusion.png")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">
                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="/ensinnocoldfusion">COLD FUSION SYSTEM</Link></h4>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>Excess heat generation systems consist of transition metals or alloys. filled with hydrogen or deuterium in some cases and in some casesConditions for excess power significantly...</p>
                                            </div>
                                            <div className="dlab-post-readmore">
                                                <Link to="/ensinnocoldfusion" title="READ MORE" rel="bookmark" className="site-button btnhover16 " target="_top">READ MORE
                                                    <i className="ti-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="#"><img src={require("../../images/energyinnovation/energy_heatboost.png")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">
                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="#">HEAT BOOSTER SYSTEM</Link></h4>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>Atmosphere Heat to Electric, Cool, Heat and Generator enhance theoretically proved by credited institute. The atmosphere heat source at 25 Celsius can be boosted and...</p>
                                            </div>
                                            <div className="dlab-post-readmore">
                                                <Link to="#" title="READ MORE" rel="bookmark" className="site-button btnhover16 " target="_top">READ MORE
                                                    <i className="ti-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="ensinnohigheffprop"><img src={require("../../images/energyinnovation/energy_highpropeller.png")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">
                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="ensinnohigheffprop">HIGH EFFICIENCY PROPELLER AND FAN</Link></h4>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>The propeller fan is a comparatively simple form of a fan with a sheet metal,...</p>
                                            </div>
                                            <div className="dlab-post-readmore">
                                                <Link to="ensinnohigheffprop" title="READ MORE" rel="bookmark" className="site-button btnhover16 " target="_top">READ MORE
                                                    <i className="ti-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default EnergyHarvesting;