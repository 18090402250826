import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer6'
// import Partner from '../layout/partner'
// import Testimonial1 from '../element/testimonial1';

const bg = require('../../images/banner/banner1.png')
// const bg2 = require('../../images/background/bg1.jpg')
// const bg3 = require('../../images/background/bg1.jpg')

class NewsVmer extends Component {

    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">News</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/newsall">News</Link></li>
                                        <li>9 September 2022</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                        <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-head text-center">
                                    <p>RENEWABLE ENERGY INNOVATION</p>
                                    <h2 className="title">V-MER Smart Vibration Analyzer Vibration analyzers from Equity Services & Solutions Co., Ltd. (Affiliates ENSERV GROUP) was rewarded New Innovation Product Award from the Department of International Trade Promotion.</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                        <div className="container">
                            <div className="section-content row">
                                <div className="col-md- col-lg-12 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="icon-bx-wraper" data-name="01">
                                        <div className="icon-content">
                                        <div className="section-content row wraper">
                                                <div className="col-lg-6 col-md-12">
                                                    <img src={require("../../images/news/v-mer/v-mer1.png")} alt="center" />
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <img src={require("../../images/news/v-mer/v-mer5.png")} alt="center" />
                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                        <h2 className="title-ourservice">9 September 2022</h2>
                                            <div className='indent-50'>
                                                <p align="justify">On 9 September 2022 time 14.00 North. Mr. Jurin Laksanavisit, Deputy Prime Minister and Minister of Commerce, presided over the ceremony to announce the success of the air conditioning industry, refrigeration, and electronic appliances “Thai Industry’s Export Achievement Ceremony”.  Bangkok RHVAC 2022 and Electrical and Electronics Trade shows (Bangkok E&E 2022) was held in hall 103-104, BITEC Exhibition and Convention Center (BITEC).</p>
                                                <p align="justify">Mr. Jurin said the electrical and electronics industries are very important to the Thai economy. This industry creates more jobs in the country and circulate around 2 trillion million. Baht from export.  From 7 months of this year Thailand can export to 1.4 trillion million.  Today, Thailand is ranked the 2nd of the world's major electrical and electronics manufacturing bases air conditioning-only exports and export refrigeration. Besides, Thailand was considered as the 6th of the world, in exporting refrigerators, soaking rooms and cold storage. Since we can cover a full range of productions from upstream to downstream, including innovation. For this year's air conditioning and refrigeration trade show (Bangkok RHVAC 2022) and electrical and electronics trade shows (Bangkok E&E 2022) is organized under concept "ONE STOP SOLUTIONS - New Innovations for New Global Challenges” and was considered a successful year because there were more than 100 people, 100 companies and 400 booths attending the event.
                                                    "It is expected that there will be at least 5,000 visitors from both domestic and international levels and aims to generate at least 9,000 million baht within a year. So far, three-day event has sold 8,500 million baht. THE BEST OF RHVAC AND E&E PRODUCT AWARD 2022, which will help to promote the industry to continue, develop and continue to dominate the industry in the global market," said the Deputy Prime Minister and Minister of Commerce.</p>
                                                <p><a href={"https://th.postupnews.com/2022/09/bangkok-rhvac-2022-bangkok-eande-2022.html?m=1"} target={"_blank"}>https://th.postupnews.com/2022/09/bangkok-rhvac-2022-bangkok-eande-2022.html?m=1</a></p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default NewsVmer;