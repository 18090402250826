import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from '../layout/header1';
import Footer from '../layout/footer';

const bg = require('../../images/banner/banner1.png')

class Esma extends Component {

render() {
        return (
            <>
                <Header />
                    <div className="page-content bg-white">
                        <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                            <div className="container">
                                <div className="dlab-bnr-inr-entry">
                                    <h1 className="text-white">ENERGY ENHANCEMENT</h1>
                                    <div className="breadcrumb-row">
                                        <ul className="list-inline">
                                            <li><Link to ="/">HOME</Link></li>
                                            <li><Link to ="ourproduct">OUR PROJECT</Link></li>
                                            <li><Link to ="ensinnoslider">ENERGY INNOVATION</Link></li>
                                            <li><Link to ="ee">ENERGY ENHANCEMENT</Link></li>
                                            <li>ESMA</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-content row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="hero-content">
                                            <h1 className="title align-items-center tocreate">Enserv Solar Managament Algorithm</h1>
                                                <div className="section-content row">
                                                    <div className="col-lg-6 col-md-12">
                                                        <p align="justify">Present situation of Solar Farm

        Hidden power loss always occurred in every Solar Farm. The difficulty of detecting can be seen especially in the enormous, large number of Solar panels. These are reasons for revenue inefficiency in a solar farm:
        Inadequate system design and sizing: Proper system design and sizing are crucial to optimize the energy output of a solar farm. If the design does not consider factors such as panel efficiency, inverter capacity, or optimal string configuration, it can result in suboptimal energy generation and revenue loss.
        Poor performance monitoring and maintenance: Regular performance monitoring and maintenance are essential to ensure the solar farm operates at its peak efficiency. If monitoring systems are inadequate or maintenance practices are neglected, issues such as soiling of panels, faulty inverters, or shading from vegetation can go unnoticed, leading to decreased energy production and revenue loss.
        Inefficient operation and maintenance practices: Inefficient operation and maintenance practices can contribute to revenue inefficiency. This includes ineffective scheduling of maintenance activities, delayed response to equipment failures, or lack of proactive measures to address performance issues. Improper management of these aspects can lead to prolonged downtime, reduced energy production, and revenue loss.
        </p>
                                                        </div>
                                                        <div className="col-lg-6 col-md-12">
                                                        <img src={require("../../images/our-services/solarplant/pic3.jpg")} alt=""/>
                                                        </div>
                                                    </div>

                                            <br/>
                                            <Link to="#" className="site-button appointment-btn btnhover13">Learn More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <Footer />
            </>
        )
    }
}
export default Esma;