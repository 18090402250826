import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from '../layout/header';
import Footer from '../layout/footer6';

const bg = require('../../images/banner/bnharvesting.png')

class EnsInnoBess extends Component {

    render() {


        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">BATTERY ENERGY STORAGE SYSTEM</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">HOME</Link></li>
                                        <li><Link to="ourproduct">OUR PROJECT</Link></li>
                                        <li><Link to="ensinnoslider">ENERGY INNOVATION</Link></li>
                                        <li><Link to="es">ENERGY STORAGE</Link></li>
                                        <li>BATTERY ENERGY STORAGE SYSTEM</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                        <div className="container">
                            <div className='row'>
                                <div className='col-md-4 col-lg-6 col-sm-12'>
                                    <img src={require("../../images/energyinnovation/energy_battery1.jpg")} alt="" />
                                </div>
                                <div className='col-md-8 col-lg-6 col-sm-12'>
                                    <blockquote>
                                        <h2 className='cop26'> Enserv prioritizes research and development (R&D)</h2>
                                        <p align="justify">in battery technology for Battery Energy Storage Systems (BESS) to stay at the forefront of innovation and address critical energy challenges. By investing in R&D for BESS batteries, Enserv aims to enhance energy storage capabilities, improve efficiency, and extend the lifespan of batteries. This focus allows Enserv to offer advance solutions that contribute to grid stability, support renewable energy integration, and provide reliable backup power. R&D in battery technology aligns with Enserv's commitment to sustainable and resilient energy solutions, ensuring the company remains a leader in the evolving landscape of clean and efficient energy storage.</p>
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default EnsInnoBess;