import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from '../layout/header';
import Footer from '../layout/footer6';

const bg = require('../../images/banner/banner1.png')

class EnergyEnhancement extends Component {

    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">ENERGY ENHANCEMENT</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">HOME</Link></li>
                                        <li><Link to="ourproduct">OUR PROJECTS</Link></li>
                                        <li><Link to="ensinnoslider">ENERGY INNOVATION</Link></li>
                                        <li>ENERGY ENHANCEMENT</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                        <div className="container">
                            <h2>Elevate your energy infrastructure with our transformative solutions.</h2>
                            <p align="justify">Dive into a realm where innovation meets efficiency, reshaping how we enhance energy systems for a more sustainable and resourceful tomorrow.</p>
                            <div className='row'>
                                <div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="/ensinnobe"><img src={require("../../images/energyinnovation/energy_platform.png")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">
                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="/ensinnobe">ENSERV SOLAR MANAGMENT ALGORITHM</Link></h4>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>ENSERV Solar Management Algorithm, is an Intelligent solar farm management system which is consisted of device integrated with Artificial Intelligent (AI)....</p>
                                            </div>
                                            <div className="dlab-post-readmore">
                                                <a href={"http://www.esmaserv.com:3000/"} target={"_blank"} className="site-button appointment-btn btnhover13">READ MORE</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="/ensinnobe"><img src={require("../../images/energyinnovation/energy_solarcooling.jpg")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">
                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="/ensinnobe">SOLAR COOLING IMPROVMENT SYSTEM</Link></h4>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>Devices that reduce the heat of solar panels to increase the efficiency of producing electricity from light.</p>
                                            </div>
                                            <div className="dlab-post-readmore">
                                                <Link to="/ensinnobe" title="READ MORE" rel="bookmark" className="site-button btnhover16">READ MORE
                                                    <i className="ti-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="/ensinnobe"><img src={require("../../images/energyinnovation/energy_bio.jpg")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">
                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="/ensinnobe">BIO-ENERGY</Link></h4>
                                            </div>
                                            <br />
                                            <div className="dlab-post-text">
                                                <p>Oil Palm Trunks (OPT) is one of ENSERV Biomass,  obtained from expired palm trees to replace coal fuel.</p>
                                            </div>
                                            <div className="dlab-post-readmore">
                                                <Link to="/ensinnobe" title="READ MORE" rel="bookmark" className="site-button btnhover16">READ MORE
                                                    <i className="ti-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="/ensinnobe"><img src={require("../../images/energyinnovation/energy_propeller.png")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">
                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="/ensinnobe">HIGH EFFICIENCY PROPELLER AND FAN</Link></h4>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>#</p>
                                            </div>
                                            <div className="dlab-post-readmore">
                                                <Link to="/ensinnobe" title="READ MORE" rel="bookmark" className="site-button btnhover16">READ MORE
                                                    <i className="ti-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="/ensinnobess"><img src={require("../../images/energyinnovation/energy_battery.jpg")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">
                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="/ensinnobess">BATTERY ENERGY STORAGE SYSTEMS (BESS)</Link></h4>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>Enserv prioritizes research and development (R&D) in battery technology </p>
                                            </div>
                                            <div className="dlab-post-readmore">
                                                <Link to="/ensinnobess" title="READ MORE" rel="bookmark" className="site-button btnhover16">READ MORE
                                                    <i className="ti-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default EnergyEnhancement;