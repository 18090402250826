import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header1'
import Footer from '../layout/footer'

const bg = require('../../images/banner/banner1.png')

class CareerBm extends Component {

    render() {
        return (
            <>
                <Header/>
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">Career</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Career</Link></li>
                                        <li>Business Manager</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    

                    <div className="section-full bg-gray content-inner">
                            <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-head text-center">
                                    <p>CAREER</p>
                                    <h2 className="title">Business Manager</h2>
                                    <div className="description"><b>Department : </b>Human Resources</div>
                                    <div className="description"><b>Level : </b>Mid-Level</div>
                                    <div className="description"><b>Location : </b>Los-Angeles</div>
                                    <br/>
                                    <div className="contact-right">
                                        <Link to="/contact-4" className="site-button appointment-btn btnhover13">Apply Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                        <div className="container">
                            <div className="section-content row">
                            <br/>
                                <h4 className="title align-items-center">Job Description</h4>
                                 <div className="description">[CompanyX] is in search of a highly skilled business manager to join our team and help us continue our tradition of unparalleled [client/customer] and employee satisfaction. We’re a company who [insert mission or what you stand for here]. As an ideal candidate, you have a track record of outstanding managerial experience. You’ve previously managed day-to-day operational activities, including production, human resources, and accounting processes. You’re a motivated leader who thrives on creating a climate of inclusion, trust, and productivity. Ultimately, you’re responsible for continuing the success of our daily business practices — and ushering in a new era of growth at [CompanyX].</div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                        <div className="container">
                            <div className="section-content row">
                            <br/>
                                <h4 className="title align-items-center">Job Description</h4>
                            </div>
                            <ul>
                            <li>Strategize, implement, and oversee processes for increased productivity and growth</li>
                            <li>Design daily operational programs that produce organizational objectives</li>
                            <li>Manage employee activities, providing suitable resources</li>
                            <li>Establish and grow relationships with appropriate partners</li>
                            <li>Establish cost parameters and manage the overall P&L of this company / division</li>
                            <li>Maintain a forecast and targeting sales attainment plan</li>
                            </ul>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                        <div className="container">
                            <div className="section-content row">
                            <br/>
                                <h4 className="title align-items-center">Skills and Qualifications</h4>
                            </div>
                            <ul>
                            <li>Bachelor’s degree in business, management, or related field</li>
                            <li>At least 3 years experience as a business manager or relevant managerial role</li>
                            <li>Proven experience with business and strategy development processes</li>
                            <li>Outstanding poise in a fast-paced business environment</li>
                            <li>Superior leadership skills, with a focus in employee coaching and development</li>
                            </ul>
                            <div className="contact-right">
                                <Link to="/contact-4" className="site-button appointment-btn btnhover13">Apply Now</Link>
                            </div>
                        </div>
                    </div>
                </div>                
                <Footer/>                
            </>
        )
    }
}
export default CareerBm;