import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer6'
// import Partner from '../layout/partner'
// import Testimonial1 from '../element/testimonial1';

const bg = require('../../images/banner/banner1.png')
// const bg2 = require('../../images/background/bg1.jpg')
// const bg3 = require('../../images/background/bg1.jpg')

class NewsAll extends Component {

    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">News</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>News</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                        <div className="container">
                            <div className="section-content row">

                                <div className="col-md- col-lg-6 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="icon-bx-wraper" data-name="01">
                                        <div className="icon-content">
                                            <img src={require("../../images/news/newscambodian/Ensh_Cambodia1_KJ.jpg")} alt="" />
                                            <h2 className="title-ourservice">Enserv’news Mar.4, 2024.</h2>
                                            <p align="justify">"Enserv Holding Australia Explores Sustainable Investment in Cambodian Sorghum Industry, Engages with Prime Minister Hun Manet"</p>
                                            <br/>
                                            <div className="contact-right">
                                                <Link to="newscambodian" className="site-button appointment-btn btnhover13">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md- col-lg-6 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="icon-bx-wraper" data-name="01">
                                        <div className="icon-content">
                                            <img src={require("../../images/news/sport24/IMG_0062.jpg")} alt="" />
                                            <h2 className="title-ourservice">Feb. 29,2024</h2>
                                            <p align="justify">"Enserv Sport Day 2024: A Global Celebration of Unity and Sustainability"</p>
                                            <div className="contact-right">
                                                <Link to="newssportday24" className="site-button appointment-btn btnhover13">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md- col-lg-6 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="icon-bx-wraper" data-name="01">
                                        <div className="icon-content">
                                            <img src={require("../../images/news/Intelectual/S__29900814.jpg")} alt="" />
                                            <h2 className="title-ourservice">Thursday 21 September 2023</h2>
                                            <p align="justify">"Intelectual Property for creating Business's values "</p>
                                            <div className="contact-right">
                                                <Link to="newsintelectual" className="site-button appointment-btn btnhover13">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md- col-lg-6 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="icon-bx-wraper" data-name="01">
                                        <div className="icon-content">
                                            <img src={require("../../images/news/korean/korean3.JPG")} alt="" />
                                            <h2 className="title-ourservice">8th February 2023</h2>
                                            <p align="justify">Warmly Welcome S.Korean Ambassador to Visit "ENSERV"</p>
                                            <div className="contact-right">
                                                <Link to="korean" className="site-button appointment-btn btnhover13">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md- col-lg-6 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="icon-bx-wraper" data-name="01">
                                        <div className="icon-content">
                                            <img src={require("../../images/news/mflf/IMG-434.jpg")} alt="" />
                                            <h2 className="title-ourservice">January 26th 2023</h2>
                                            <p align="justify">"ENSERV" and "Mae Fah Luang" Signing MoU Ceremony</p>
                                            <div className="contact-right">
                                                <Link to="newsmflf" className="site-button appointment-btn btnhover13">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md- col-lg-6 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="icon-bx-wraper" data-name="01">
                                        <div className="icon-content">
                                            <img src={require("../../images/news/brandNew/brandnew1.png")} alt="" />
                                            <h2 className="title-ourservice">Monday 2nd November 2022</h2>
                                            <p align="justify">Say Hello to "ENSERV" Brand New Headquarters</p>
                                            <div className="contact-right">
                                                <Link to="newsbrand" className="site-button appointment-btn btnhover13">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-6 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="icon-bx-wraper" data-name="03">
                                        <div className="icon-content">
                                            <img src={require("../../images/news/emf/emf1.png")} alt="" />
                                            <h2 className="title-ourservice">August 24th, 2022</h2>
                                            <p align="justify">"Enserv Innovation Center" welcome the honorable guests...</p>
                                            <div className="contact-right">
                                                <Link to="newsemf" className="site-button appointment-btn btnhover13">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md- col-lg-6 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="icon-bx-wraper" data-name="01">
                                        <div className="icon-content">
                                            <img src={require("../../images/news/mongo/mongo2.png")} alt="" />
                                            <h2 className="title-ourservice">5-7 September 2022</h2>
                                            <p align="justify">Enserv Group attends "The First trade cooperation conference...</p>
                                            <div className="contact-right">
                                                <Link to="news1mongo" className="site-button appointment-btn btnhover13">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="icon-bx-wraper" data-name="02">
                                        <div className="icon-content">
                                            <img src={require("../../images/news/v-mer/v-mer1.png")} alt="" />
                                            <h2 className="title-ourservice">9 September 2022</h2>
                                            <p align="justify">V-MER Smart Vibration Analyzer.</p>



                                            <div className="contact-right">
                                                <Link to="newsvmer" className="site-button appointment-btn btnhover13">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="icon-bx-wraper" data-name="03">
                                        <div className="icon-content">
                                            <img src={require("../../images/news/news3.png")} alt="" />
                                            <h2 className="title-ourservice">5 SEPTEMBER 2017</h2>
                                            <p align="justify">Enserv Holding employees and local communities...</p>

                                            <div className="contact-right">
                                                <Link to="#" className="site-button appointment-btn btnhover13">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="icon-bx-wraper" data-name="04">
                                        <div className="icon-content">
                                            <img src={require("../../images/news/news4.png")} alt="" />
                                            <h2 className="title-ourservice">8 - 10 MARCH 2017</h2>
                                            <p align="justify">Enserv Holding opened the exhibition in SETA 2017 at BITEC...</p>

                                            <div className="contact-right">
                                                <Link to="#" className="site-button appointment-btn btnhover13">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default NewsAll;
