import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from '../layout/header';
import Footer from '../layout/footer6';

const bg = require('../../images/banner/banner1.png')

class LifeHealthEncare extends Component {

    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">ENCARE</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">HOME</Link></li>
                                        <li><Link to="ourproduct">OUR PROJECTS</Link></li>
                                        <li><Link to="lifehealth">LIFE & HEALTH SOLUTION</Link></li>
                                        <li>ENCARE</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full content-inner bg-white">
                        <div className="container">

                            <div className="row">
                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="#"><img src={require("../../images/encare/telemedicin.jpg")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">
                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="#">TELEMEDICINE</Link></h4>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p align="justify">ENSERV is developing its own wearable device to read vital parameters of human body, collect real time data and interpret a tendency of data by using Al to advise health concerns. These data sets will be used extensively with our telemedicine platform.</p>
                                            </div>
                                            {/* <div className="dlab-post-readmore">
                                                <Link to="/es" title="READ MORE" rel="bookmark" className="site-button btnhover16">READ MORE
                                                    <i className="ti-arrow-right"></i>
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="#"><img src={require("../../images/encare/watchyourback.jpg")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">

                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="#"> WATCH YOUR BACK</Link></h4>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p align="justify">Super smart surveillance camera notifying you any incidents, accidents, and life-threatening situations before it really happens by using Al to analyst actions and behaviors of an object on the camera and automatically alert to police or security personnels.</p>
                                            </div>
                                            {/* <div className="dlab-post-readmore">
                                                <Link to="/eh" title="READ MORE" rel="bookmark" className="site-button btnhover16">READ MORE
                                                    <i className="ti-arrow-right"></i>
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="#"><img src={require("../../images/encare/smartlegalplatform.png")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">

                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="#">SMART LEGAL PLATFORM</Link></h4>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p align="justify">The user-friendly and easy-to-use platform for people with or without legal background can seek for assistance in legal concerns and litigation. The platform will provide knowledge and database about laws that anyone can look up as a freeware.</p>
                                            </div>
                                            {/* <div className="dlab-post-readmore">
                                                <Link to="/ee" title="READ MORE" rel="bookmark" className="site-button btnhover16">READ MORE
                                                    <i className="ti-arrow-right"></i>
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                </div>
                <Footer />
            </>
        )
    }
}
export default LifeHealthEncare;