import React, { Component } from 'react';

class SlideEnerInnoHbs1 extends Component {


    render() {
        return (
            <>

                    <div className="page-content bg-white">
                        <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-content row">
                                    

                                    <div className="col-lg-12 col-md-12">
                                        <div className="hero-content">
                                            <h1 className="title align-items-center tocreate">Heat Booster System (HBS)</h1>
                                            
                                            <p className='tocreate'>Atmosphere Heat to Electric, Cool, Heat and Generator Enhance</p>
                                            <p>Working Pair Material and Feasibility</p>
                                            <ul>
                                                <li>Planning to discuss about new working pairs concept with Fraunhofer and this is the way to go for this particular heat booster project.</li>
                                                <li>Combining multi-effect approach to single effect approach itself is a challenge as it will definitely reduce the COP of overall system dramatically.</li>
                                            </ul>
                                        </div>
                                    </div>

                                    
                                </div>
                            </div>
                        </div>
                    </div>  

            </>
        )
    }
}
export default SlideEnerInnoHbs1;