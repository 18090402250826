import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header';
import Footer from '../layout/footer6';
import '../../css/plugins.css';
import '../../css/style.css';
import '../../css/skin/skin-1.css';
import '../../css/templete.min.css';
import ScrollToTop from '../element/scrollToTop';
import HomeSlider from '../element/homeslider1';
import { homeSliderContent12 } from '../element/SliderContent';
// import PopUp1 from '../layout/popup1';
import PopUp from '../layout/popup';



class Index extends Component {

    render() {
        return (

            <div>


                <div className="skin-1">

                    <Header />
                    {/* <div content="fit"><PopUp /></div> */}

                    <HomeSlider
                        data={homeSliderContent12}
                        contentWrapperCls="sliderStyle4"
                        btnCls="site-button"
                        btnText="ABOUT US"
                        // btn2Text="OUR SERVICES"
                        show2Btn
                    />
                    <div className="section-full bg-img-fix content-inner-2 overlay-black-dark contact-action style2">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 m-b0 d-flex">
                                    <img src={require("../../images/water.png")} alt="" />
                                </div>

                                <div className="col-lg-6 m-b0">
                                    <div className="p-a30 clearfix text-white">
                                        <h3 className="worldsruvives">World survives and step back from its breaking boundaries</h3>
                                        {/* <div className="dzFormMsg"></div> */}
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4 className='cop26'>1.5 ํC</h4>
                                                <div className='description worldsruvives text-white'>Limited the temperature increase to 1.5 ํC following Paris Agreement</div>
                                            </div>
                                            <div className="col-md-6">
                                                <h4 className='cop26'>0</h4>
                                                <div className='description worldsruvives text-white'>0 Carbon and Greenhouse Gases emission</div>
                                            </div>

                                        </div>
                                        <br />

                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4 className='cop26'>100 %</h4>
                                                <div className='description worldsruvives text-white'>100% Renewable Energy</div>
                                            </div>
                                            <div className="col-md-6">
                                                <h4 className='cop26'>COP26</h4>
                                                <div className='description worldsruvives text-white'>ENSERV is hornored to join COP26 as Australian Delegation</div>
                                                <br />
                                                <br />
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 m-b0 d-flex">
                                    <div className="p-a30 clearfix radius-sm">
                                        <h3 className="worldsruvives text-white" >Human beings live happily in this World</h3>
                                        {/* <div className="dzFormMsg"></div> */}
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4 className='cop26'>HEALTH</h4>
                                                <div className='description worldsruvives text-white'>Enhance good Health and balance diet.</div>
                                            </div>
                                            <div className="col-md-6">
                                                <h4 className='cop26'>LIFE</h4>
                                                <div className='description worldsruvives text-white'>Life security</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 m-b0">
                                    <img src={require("../../images/healthy.png")} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <partners />

                    <div className="section-full bg-gray content-inner">
                        <div className="container">
                            <div className="section-head text-center">
                                <h4 className='cop26'> OUR PROJECT</h4>
                                <h2>Learn about our project</h2>
                            </div>
                            <div className="section-content row" align="center">
                                <div className="col-md-6 col-lg-6 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="icon-bx-wraper" data-name="01">
                                        <img src={require("../../images/icon/renewable.png")} alt="" />
                                        <div className="icon-content">
                                            <Link to="/ensinnoslider" className='text-btnlearnmore' target="_top"><h2 className="title-ourservice">ENERGY</h2></Link>
                                            <p>Leading in sustainable renewable energy innovation</p>
                                        </div>
                                        <br />
                                        <div>
                                            <Link to="/ensinnoslider" className='text-btnlearnmore' target="_top">Learn More</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">
                                    <div className="icon-bx-wraper" data-name="02">
                                        <img src={require("../../images/icon/health.png")} alt="" sizes="10" />
                                        <div className="icon-content">
                                            <Link to="/lifehealth" className='text-btnlearnmore' target="_top"><h2 className="title-ourservice">LIFE & HEALTH SOLUTIONS</h2></Link>
                                            <p>One of the leading reliable and trustable health & life security solutions</p>
                                        </div>
                                        <div>
                                            <Link to="/lifehealth" className='text-btnlearnmore' target="_top">Learn More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                        <div className="container">
                            <div className="section-head text-center">
                                <h4 className='cop26'>ABOUT US</h4>
                                <h2>Learn about Chairman Statement</h2>
                            </div>
                            <div className="section-content row">
                                <div className="col-lg-6 col-md-12">
                                    <img src={require("../../images/chairman.png")} alt="" />
                                </div>

                                <div className="col-lg-6 col-md-12">
                                    <div className="hero-content">
                                        <h1 className="title align-items-center">Chairman Message</h1>
                                        <div className="description" align="justify">The vision of our company is to be the Ultimate Energy Solutions Provider. To achieve our vision, we put our resources into two core businesses; Clean Energy Innovation and Clean Energy Generation.</div>
                                        <br />
                                        <div className="contact-right">
                                            <Link to="/about-1" className="site-button appointment-btn btnhover13">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 m-b0">
                                    <img src={require("../../images/logomart.png")} alt="" />
                                </div>
                                <div className="col-lg-6 m-b0 d-flex">
                                    <div className="p-a30 clearfix radius-sm">
                                        <h4 className='cop26'>ULTIMATE GOAL</h4>
                                        <h2>To create innovation for sustainable human life</h2>
                                        {/* <div className="dzFormMsg"></div> */}
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4 className='tocreate'>TO CREATE</h4>
                                                <div className='description'>We are a creator not just a user</div>
                                            </div>
                                            <div className="col-md-6">
                                                <h4 className='innovation'>INNOVATION</h4>
                                                <div className='description'>Our creation does not currently exist</div>
                                            </div>
                                        </div>
                                        <br />

                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4 className='sustainable'>SUSTAINABLE</h4>
                                                <div className='description'>Innovation sustains both ecologically and economically</div>
                                            </div>
                                            <div className="col-md-6">
                                                <h4 className='humanlife'>HUMAN LIFE</h4>
                                                <div className='description'>The most sensitive difficult and complicated thing in this World.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <Footer hideContactInfo displayNewsLetter />
                    <ScrollToTop className="icon-up" />
                </div>
            </div>
        )
    }
}
export default Index;