export const blogContent1 = [
    {
        image: require("../../images/blog/grid/pic1.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/blog/grid/pic2.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/blog/grid/pic3.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/blog/grid/pic1.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/blog/grid/pic2.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/blog/grid/pic3.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
]

export const blogContent2 = [
    {
        image: require("../../images/our-services/ship/pic1.jpg"),
        title:"Seven Doubts You Should",
        author:"Jack",
    },
    {
        image: require("../../images/our-services/ship/pic1.jpg"),
        title:"Seven Doubts You Should",
        author:"Jack",
    },
    {
        image: require("../../images/our-services/ship/pic2.jpg"),
        title:"Seven Doubts You Should",
        author:"Jack",
    },
    {
        image: require("../../images/our-services/ship/pic3.jpg"),
        title:"Seven Doubts You Should",
        author:"Jack",
    },
    {
        image: require("../../images/our-services/ship/pic2.jpg"),
        title:"Seven Doubts You Should",
        author:"Jack",
    },
]


export const blogContent3 = [
    {
        image: require("../../images/blog/grid/pic1.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/blog/grid/pic2.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/blog/grid/pic3.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/blog/grid/pic1.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/blog/grid/pic2.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/blog/grid/pic3.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
]


export const blogContent4 = [
    {
        image: require("../../images/our-work/leather/pic1.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/leather/pic1.jpg"),
        title:"Seven Doubts You Should",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/leather/pic2.jpg"),
        title:"Seven Doubts You Should",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/leather/pic3.jpg"),
        title:"Seven Doubts You Should",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/leather/pic2.jpg"),
        title:"Seven Doubts You Should",
        author:"Jack",
    },
]

export const blogContent5 = [
    {
        image: require("../../images/our-work/nuclear/pic1.jpg"),
        title:"Why You Should Not Go To Industry",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/nuclear/pic2.jpg"),
        title:"Seven Doubts You Should Clarify About",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/nuclear/pic3.jpg"),
        title:"Seven Doubts You Should",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/nuclear/pic4.jpg"),
        title:"Seven Doubts You Should Clarify About",
        author:"Jack",
    },
]

export const blogContent6 = [
    {
        image: require("../../images/our-work/beer/pic1.jpg"),
        title:"Why You Should Not Go To Industry",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/beer/pic2.jpg"),
        title:"Seven Doubts You Should Clarify About",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/beer/pic3.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/beer/pic2.jpg"),
        title:"Seven Doubts You Should Clarify About",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
]

export const blogContent7 = [
    {
        image: require("../../images/our-work/car/pic1.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/car/pic2.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/car/pic3.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/car/pic1.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/car/pic2.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/car/pic3.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
]

export const blogContent8 = [
    {
        image: require("../../images/our-work/mining/pic1.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/mining/pic2.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/mining/pic3.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/mining/pic1.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/mining/pic2.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/mining/pic3.jpg"),
        title:"Seven Doubts You Should",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
]

export const blogContent9 = [
    {
        image: require("../../images/our-work/plastic/pic1.jpg"),
        title:"Why You Should Not Go To Industry",
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/plastic/pic2.jpg"),
        title:"Seven Doubts You Should Clarify About",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/plastic/pic3.jpg"),
        title:"Seven Doubts You Should",
        author:"Jack",
    },
    {
        image: require("../../images/our-work/plastic/pic4.jpg"),
        title:"Seven Doubts You Should Clarify About",
        author:"Jack",
    },
]

export const ExibitionContent1 = [
    {
        image: require('./../../images/our-work/solarplant/pic1.jpg'),
    },
    {
        image: require('./../../images/our-work/solarplant/pic2.jpg'),
    },
    {
        image: require('./../../images/our-work/solarplant/pic3.jpg'),
    },
    {
        image: require('./../../images/our-work/solarplant/pic4.jpg'),
    },
]

export const exibitionContent2 = [
    {
        image: require('./../../images/gallery/leather/pic1.jpg'),
    },
    {
        image: require('./../../images/gallery/leather/pic2.jpg'),
    },
    {
        image: require('./../../images/gallery/leather/pic3.jpg'),
    },
    {
        image: require('./../../images/gallery/leather/pic4.jpg'),
    },
]


export const projectContent1 = [
    {
        image: require('../../images/our-work/steelplant/pic10.jpg'),
        projectName: 'Manufacturing',
    },
    {
        image: require('../../images/our-work/steelplant/pic9.jpg'),
        projectName: 'Iron Making',
    },
    {
        image: require('../../images/our-work/steelplant/pic8.jpg'),
        projectName: 'Steel Pipes',
    },
    {
        image: require('../../images/our-work/steelplant/pic7.jpg'),
        projectName: 'Structural Steel',
    },
    {
        image: require('../../images/our-work/steelplant/pic10.jpg'),
        projectName: 'Manufacturing',
    },

]

export const projectContent2 = [
    {
        image: require('../../images/our-work/solarplant/pic1.jpg'),
        projectName: 'Manufacturing',
    },
    {
        image: require('../../images/our-work/solarplant/pic2.jpg'),
        projectName: 'Iron Making',
    },
    {
        image: require('../../images/our-work/solarplant/pic3.jpg'),
        projectName: 'Steel Pipes',
    },
    {
        image: require('../../images/our-work/solarplant/pic4.jpg'),
        projectName: 'Structural Steel',
    },
	{
        image: require('../../images/our-work/solarplant/pic3.jpg'),
        projectName: 'Steel Pipes',
    },
    {
        image: require('../../images/our-work/solarplant/pic4.jpg'),
        projectName: 'Structural Steel',
    },

]

export const servicesContent1 = [
    {
        serviceName:"Wind Turbines",
        icon:"flaticon-worker"
    },
    {
        serviceName:"Solar Panels",
        icon:"flaticon-factory"
    },
    {
        serviceName:"Maintenance",
        icon:"flaticon-settings"
    },
    {
        serviceName:"Maintenance",
        icon:"flaticon-engineer-1"
    }
]

export const serviceContent2 = [
    {
        serviceName:"Material Engineering",
        icon:"flaticon-worker"
    },
    {
        serviceName:"Power and Energy",
        icon:"flaticon-factory"
    },
    {
        serviceName:"Agricultural Engineering",
        icon:"flaticon-settings"
    },
    {
        serviceName:"Petroleum Engineering",
        icon:"flaticon-engineer-1"
    },
    {
        serviceName:"Material Engineering",
        icon:"flaticon-worker"
    },
]

export const servicesContent3 = [
    {
        image: require("../../images/our-work/solarplant/pic1.jpg"),
        description:"Our advanced algorithm continuously analyzes real-time data, weather patterns, and historical performance to maximize energy output. Say hello to increased efficiency and higher revenue potential!",
        serviceName:"Optimized Energy Harvesting:",
    },
    {
        image: require("../../images/our-work/solarplant/pic2.jpg"),
        description:"intelligently adjusts solar panel tilt angles, tracking systems, and battery charging parameters to ensure every ray of sunlight is harnessed effectively, regardless of weather conditions.",
        serviceName:"Adaptive Control: ESMA™ ",
    },
    {
        image: require("../../images/our-work/solarplant/pic3.jpg"),
        description:"Proactively prevent downtime and costly repairs with ESMA™. It predicts equipment failures and provides timely alerts, allowing your team to carry out maintenance before any issues arise.",
        serviceName:"Predictive Maintenance:",
    },
    {
        image: require("../../images/our-work/solarplant/pic1.jpg"),
        description:"Stay ahead of demand fluctuations and grid requirements. ESMA™ accurately predicts energy demands, enabling your solar farm to be a reliable and valuable energy supplier.",
        serviceName:"AI-Driven Forecasting:",
    },
    {
        image: require("../../images/our-work/solarplant/pic2.jpg"),
        description:"Our algorithm is designed for easy integration into your existing solar farm management systems, making the transition hassle-free and saving you valuable time and resources.",
        serviceName:"Seamless Integration:",
    },
    {
        image: require("../../images/our-work/solarplant/pic3.jpg"),
        description:"Monitor your solar farm's performance in real-time, access comprehensive analytics, and receive actionable insights via an intuitive dashboard. Make data-driven decisions for optimized operations!",
        serviceName:"Real-Time Monitoring:",
    }
]

export const servicesContent4 = [
    {
        image: require("../../images/our-services/pic1.jpg"),
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        serviceName:"Wind Turbines",
    },
    {
        image: require("../../images/our-services/pic2.jpg"),
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        serviceName:"Solar Panels",
    },
    {
        image: require("../../images/our-services/pic3.jpg"),
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        serviceName:"Maintenance",
    },
    {
        image: require("../../images/our-services/pic1.jpg"),
        description:"All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
        serviceName:"Wind Turbines",
    },
]

export const homeSliderContent2 = [
    {
        image: require("../../images/main-slider/slide4.jpg"),
        title:"GAS INDUSTRY",
        description:"Industry is ready to help you in making unique-looking and best website in a moment."
    },
    {
        image: require("../../images/main-slider/slide5.jpg"),
        title:"OIL INDUSTRY",
        description:"Industry is ready to help you in making unique-looking and best website in a moment."
    }
]

export const homeSliderContent3 = [
    {
        image: require("../../images/main-slider/slide21.jpg"),
        title:"Economy Needs A Healthy Steel Industry",
    },
    {
        image: require("../../images/main-slider/slide22.jpg"),
        title:"Economy Needs A Healthy Steel Industry",
    }
]

export const homeSliderContent4 = [
    {
        image: require("../../images/main-slider/slide1.jpg"),
        title:"Fast and Reliable Electrical services",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
        },
    {
        image: require("../../images/main-slider/slide2.jpg"),
        title:"Fast and Reliable Electrical services",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    }
]

export const homeSliderContent5 = [
    {
        image: require("../../images/main-slider/slide9.jpg"),
        title:"WE BUILD YOUR DREAM",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    },
    {
        image: require("../../images/main-slider/slide10.jpg"),
        title:"WE ARE CONSTRUCT",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    }
]


export const homeSliderContent6 = [
    {
        image: require("../../images/main-slider/slide82.jpg"),
        title:"ESMA ",
        description:"ENSERV Solar Management Algorithm.",
        description1:"Boost Your Solar Farm Efficiency with Cutting-Edge Management Algorithm!"
    },
    {
        image: require("../../images/main-slider/slide12.jpg"),
        title:"ESMA ",
        description:"ENSERV Solar Management Algorithm.",
        description1:"Boost Your Solar Farm Efficiency with Cutting-Edge Management Algorithm!"
    }
]

export const homeSliderContent7 = [
    {
        subTitle:"Our Latest Item Food Industry",
        image: require("../../images/main-slider/slide13.jpg"),
        title:"Our Latest Item Food Industry ",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    },
    {
        subTitle:"Our Latest Item Food Industry",
        image: require("../../images/main-slider/slide14.jpg"),
        title:"Our Latest Item Food Industry ",
        description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
    }
]

export const homeSliderContent8 = [
    {
        subTitle:"INVESTMENT OPPORTUNITIES IN LEATHER",
        image: require("../../images/main-slider/slide25.jpg"),
        title:"Welcome Leather Industry",
    },
    {
        subTitle:"INVESTMENT OPPORTUNITIES IN LEATHER",
        image: require("../../images/main-slider/slide24.jpg"),
        title:"Welcome Leather Industry",
    }
]

export const homeSliderContent9 = [
    {
        subTitle:"INVESTMENT OPPORTUNITIES IN LEATHER",
        image: require("../../images/main-slider/slide23.jpg"),
        title:"Nuclear Power Plants",
        description:"Great Lessons You Can Learn From Nuclear Plant"
    }
]

export const homeSliderContent10 = [
    {
        subTitle2:"Sale Car – Buy Car – Car Service",
        image: require("../../images/main-slider/slide29.jpg"),
        title:"Auto Mobile Industry",
        description:" With 19+ years’ experience in combining traditions and innovations, Arty creates settings to inspire the way people live, learn, heal, work and play."
    }
]

export const homeSliderContent11 = [
    {
        subTitle:"Value Creation Form Waste ",
        image: require("../../images/main-slider/slide19.jpg"),
        title:"Plastic Waste Managment & Recycling",
        description:"Powering Progress Through Plastic"
    }
]

export const homeSliderContent12 = [
    {
        image: require("../../images/background/bg4.jpg"),
        title:"ULTIMATE GOAL",
        description:"To create innovation for sustainable human life"
        },
    {
        image: require("../../images/background/bg4.jpg"),
        title:"ULTIMATE GOAL",
        description:"To create innovation for sustainable human life"
    }
]

export const popnewssliderContent = [
    {
        id: "01",
        image: require("../../images/news/investment/inv1.jpg")
    },
    {
        id: "02",
        image: require("../../images/news/investment/inv2.jpg")
    },
    {
        id: "03",
        image: require("../../images/news/investment/inv3.jpg")
    }
]

export const popnews1sliderContent = [
    {
        id: "01",
        image: require("../../images/news/Intelectual/cover1.png")
    },
    {
        id: "02",
        image: require("../../images/news/Intelectual/cover2.png")
    },
    {
        id: "03",
        image: require("../../images/news/Intelectual/cover3.png")
    },
    {
        id: "04",
        image: require("../../images/news/Intelectual/cover4.png")
    }
]