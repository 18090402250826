import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer6'
// import Partner from '../layout/partner'
// import Testimonial1 from '../element/testimonial1';

const bg = require('../../images/banner/banner1.png')
// const bg2 = require('../../images/background/bg1.jpg')
// const bg3 = require('../../images/background/bg1.jpg')

class NewsBrand extends Component {

    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">Brand New Headquarters</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/newsall">News</Link></li>
                                        <li>2nd November 2022</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                        <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-head text-center">

                                    <h2 className="title">Say Hello to "ENSERV" Brand New Headquarters.</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                        <div className="container">
                            <div className="section-content row">
                                <div className="col-md-12 col-lg-12 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="icon-bx-wraper" data-name="01">
                                        <div className="icon-content">
                                            <div className="section-content row">
                                                <div className="col-lg-6 col-md-12">
                                                    <img src={require("../../images/news/brandNew/brandnew5.png")} alt="center" />
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <img src={require("../../images/news/brandNew/brandnew6.png")} alt="center" />
                                                </div>

                                            </div>




                                            <h2 className="title-ourservice">2nd November 2022</h2>
                                            <p>On the auspicious of Monday 2nd November 2022, ENSERV's Chairman "Mr.Thanachat Pochana (Khun Jim)" including Executives and staff gather to worship the holy objects at the brand new headquarters in the capital Bangkok. The "ENSERV" headquarters is located at the CAT Tower (Bang-rak), 24th floor. Decorating with the modern design and yet merges with "Create Innovation for Sustainable Human Life" fundamental concept of the "ENSERV" company.</p>

                                            <blockquote>
                                                <p>“ENSERV”, Thailand's top innovative for sustainable life company is now more than ready to create greater innovations for all.</p>
                                            </blockquote>


                                            <div className="section-content row">
                                                <div className="col-lg-6 col-md-12">
                                                    <img src={require("../../images/news/brandNew/brandnew8.png")} alt="center" />
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <img src={require("../../images/news/brandNew/brandnew2.png")} alt="center" />
                                                </div>
                                            </div>
                                            <br />
                                            <div className="section-content row">
                                                <div className="col-lg-6 col-md-12">
                                                    <img src={require("../../images/news/brandNew/brandnew3.png")} alt="center" />
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <img src={require("../../images/news/brandNew/brandnew4.png")} alt="center" />
                                                </div>

                                            </div>




                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default NewsBrand;