import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer6'
// import Partner from '../layout/partner'
// import Testimonial1 from '../element/testimonial1';

const bg = require('../../images/banner/banner1.png')
// const bg2 = require('../../images/background/bg1.jpg')
// const bg3 = require('../../images/background/bg1.jpg')

class NewsEmf extends Component {

    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">News</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/allnews">News</Link></li>
                                        <li>August 24th, 2022</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                        <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-head text-center">
                                    <p>RENEWABLE ENERGY INNOVATION</p>
                                    <h2 className="title">"Enserv Innovation Center" welcome the honorable guests and introduce “EMF” the edible miracle innovation.</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                        <div className="container">
                            <div className="section-content row">
                                <div className="col-md- col-lg-12 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="icon-bx-wraper" data-name="01">
                                        <div className="icon-content">
                                            <img src={require("../../images/news/emf/emf1.png")} alt="" />
                                            <h2 className="title-ourservice">August 24th, 2022</h2>
                                            <p>On August 24th, 2022 the "Enserv Innovation Center" of "Enserv Group" welcome His Excellency “Mr.Suwaphan Tanyuvattana”, former Minister of Justice and the Office of the Prime Minister, and Professor “Kraisit Tantisirin”, senator together with delegates. "Enserv Group" proudly present "Enserv Miracle Food (EMF)", one of the innovations of the company, founded by "Mr. Thanachat Pochana (Khun Jim)", the company's president.</p>
                                            <img src={require("../../images/news/emf/emf2.png")} alt="" />
                                            <br />
                                            <br />
                                            <blockquote>
                                                <p>The highlight of the day is to introduce “Enserv Miracle Food” or “EMF”. Base on the “Nutrition Yet Delicious” concept the “EMF” is the unique healthy food that feature 4 miracle things which are delicious with artisan craft texture, high carbohydrate but low calories, functional food and finally, encourage farmer to plant the high quality and bio-agriculture.</p>
                                            </blockquote>
                                            <p>https://th.postupnews.com/2022/09/bangkok-rhvac-2022-bangkok-eande-2022.html?m=1 </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default NewsEmf;