import React, { Component } from 'react';

class SlideEnerInnoSib2 extends Component {


    render() {
        return (
            <>

                    <div className="page-content bg-white">
                        <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-content row">
                                    

                                    <div className="col-lg-12 col-md-12">
                                        <div className="hero-content">
                                            <h1 className="title align-items-center tocreate">Sodium Ion Battery</h1>
                                            
                                            <p fontcolor="red">ENSERV developing sodium-ion battery technology</p>
                                            <p align="justify">Despite these developments, sodium-ion technology is still behind lithium regarding energy density. However, scientists have noticed the advantages, such as imperviousness to temperature changes, stability, and long cycle life. These are considered valuable features in applications of specific light-duty EVs and grid energy storage in the future.</p>
                                            <p align="justify">Researchers intend to continue to refine their design for this battery technology. The team is experimenting with other techniques to reduce – and eventually eliminate – the requirement to include cobalt, which is toxic and expensive if not recovered or recycled.</p>
                                            
                                            
                                        </div>
                                    </div>

                                    
                                </div>
                            </div>
                        </div>
                    </div>  

            </>
        )
    }
}
export default SlideEnerInnoSib2;