import React, { Component } from 'react';
import Header from '../layout/header1'
import Footer from '../layout/footer'

const bg = require('../../images/banner/banner1.png')

class OurProductEnergyRenew extends Component {


    render() {
        return (
            <>
                <Header/>
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">RENEWABLE SUSTAINABLE ENERGY</h1>
                                {/* <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>Energy</li>
                                        <li>Renewable sustainable energy</li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="hero-section pt-50 home-bnr1">
                        <div className="container" align='center'>
                            <img src={require("../../images/renewmapth.png")} alt=""/>
                        </div>
                    </div>    
                        
            </div>                
                <Footer/>                
            </>
        )
    }
}
export default OurProductEnergyRenew;