import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer6'

const bg = require('../../images/banner/banner1.png')

class Career extends Component {


    render() {
        return (
            <>
                <Header/>
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">Join Us</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                    <li><Link to="/">Home</Link></li>
                                        <li>Join Us</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="section-full bg-gray content-inner">
                        <div className="section-full bg-gray content-inner">
                            <div className="container">


                                {/* <div className="section-full dlab-we-find bg-img-fix p-t20 p-b20 bg-white wow fadeIn" data-wow-duration="2s" data-wow-delay="0.6s">
                                    <div className="container">
                                        <div className="section-content">
                                            <div className="section-head text-center"></div>
                                            <BannerCareerSlider hideBorder/>
                                        </div>
                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className='dlab-quik-search On form'>
                                    <p>search...</p>
                                </div>
                            <div className="section-head text-center">
                                    <h2 className="title">Available Positions</h2>
                                    <p>“Enserv is constantly looking out for smart and ambitious people wanting to play their role in creating innovation for a sustainable human life.  To be part of something truly special, please send your resume confidentially to info@enserv.co.th and a member of our team will be in contact.”</p>
                                </div>
                                {/* <div className="section-content row">
                                    <div className="col-md-6 col-lg-4 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                        <div className="icon-bx-wraper" data-name="01">
                                            <div className="icon-content">
                                                <h2 className="title-ourservice">Business Manager</h2>
                                                <p><span>Human Resources</span> <br/>Mid-Level <br/>Los Angeles</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-6 col-lg-4 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">
                                        <div className="icon-bx-wraper" data-name="02">
                                            <div className="icon-content">
                                                <h2 className="title-ourservice">Digital Marketing Manager</h2>
                                                <p><span>Sales</span> <br/>Mid-Level<br/>New York</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
                                        <div className="icon-bx-wraper" data-name="03">
                                            <div className="icon-content">
                                                <h2 className="title-ourservice">Product Manager</h2>
                                                <p><span>Account</span> <br/>Junior<br/>Atlanta</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
                                        <div className="icon-bx-wraper" data-name="04">
                                            <div className="icon-content">
                                                <h2 className="title-ourservice">Computer Scientist</h2>
                                                <p><span>Human Resources</span> <br/>Mid-Level<br/>Atlanta</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
                                        <div className="icon-bx-wraper" data-name="05">
                                            <div className="icon-content">
                                                <h2 className="title-ourservice">Media Buyer</h2>
                                                <p><span>Business Development</span> <br/>Senior<br/>Los Angeles</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
                                        <div className="icon-bx-wraper" data-name="06">
                                            <div className="icon-content">
                                                <h2 className="title-ourservice">Branch Manager</h2>
                                                <p><span>Human Resources</span> <br/>Senior<br/>Remote</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
                                        <div className="icon-bx-wraper" data-name="07">
                                            <div className="icon-content">
                                                <h2 className="title-ourservice">Chief Customer Officer</h2>
                                                <p><span>Business Development</span> <br/>Senior<br/>New York</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
                                        <div className="icon-bx-wraper" data-name="08">
                                            <div className="icon-content">
                                                <h2 className="title-ourservice">Chief Data Officer</h2>
                                                <p><span>Services</span> <br/>Mid_Level<br/>Atlanta</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                </div>
                <Footer/>
            </>
        )
    }
}
export default Career;