import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from '../layout/header';
import Footer from '../layout/footer6';

const bg = require('../../images/banner/lithiumsulphurbattery.png')



class EnsinnoUltracapaSlider extends Component {

    render() {

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true
        };

        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">ULTRACAPACITORS</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">HOME</Link></li>
                                        <li><Link to="ourproduct">OUR PROJECTS</Link></li>
                                        <li><Link to="ensinnoslider">ENERGY INNOVATION</Link></li>
                                        <li><Link to="es">ENERGY STORAGE</Link></li>
                                        <li>ULTRACAPACITORS</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full content-inner">
                        <div className="container">
                            <div className='section-content-row'>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-12 column-gap'>
                                        <img src={require("../../images/energyinnovation/energy_ultracapa.jpg")} alt="" />
                                        <br />
                                        <br />
                                        <p><a href={"https://www.aepint.nl/"} target={"_blank"}>https://www.aepint.nl/</a></p>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <div className="dlab-post-text">
                                            <blockquote><p align="justify">Our ultracapacitor. so-called supercapacitor. Like a battery is a mean to store and release electricity but its energy stored in a static state making it better at rapidly charging and discharging energy. <br /><br /> Our ultracapacitor consists of an advance nano carbon as a material enhancing the performance. <br /><br />To utilize batteries connecting to an electrical network, requires batteries, additional components and a high-
                                                level control system to coordinate the operation of all components in the system. Different battery requires
                                                different schematic and unique control system. ENSERV with our partners are intensively conducting research and
                                                study on the BESS for lithium sulfur and sodium ion batteries for an upscaling in the future.
                                            </p></blockquote>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <Footer />
            </>
        )
    }
}
export default EnsinnoUltracapaSlider;