import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from '../layout/header1';
import Footer from '../layout/footer';

const bg = require('../../images/banner/bnharvesting.png')

class EnsInnoHighEffProp extends Component {

    render() {


        return (
            <>
                <Header />
                    <div className="page-content bg-white">
                        <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                            <div className="container">
                                <div className="dlab-bnr-inr-entry">
                                    <h1 className="text-white">HIGH EFFICIENCY PROPELLER AND FAN</h1>
                                    <div className="breadcrumb-row">
                                        <ul className="list-inline">
                                            <li><Link to="/">HOME</Link></li>
                                            <li><Link to ="ourproduct">OUR PROJECT</Link></li>
                                            <li><Link to ="ensinnoslider">ENERGY INNOVATION</Link></li>
                                            <li><Link to ="es">ENERGY HARVESTING</Link></li>
                                            <li>HIGH EFFICIENCY PROPELLER AND FAN</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section-full bg-gray content-inner">
                            <div className="container">
                            <h1 className="title align-items-center tocreate">High Efficiency Propeller and Fan</h1>
                                <p align="justify">The propeller fan is a comparatively simple form of a fan with a sheet metal, 3–6 bladed impeller directly driven by a motor mounted in the airstream. Efficiencies up to 75% can be achieved, though pressure development is low and overloading can occur if the fan is installed in a system with too high a resistance.</p>
                            </div>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                        </div>
                    </div>
                <Footer />
            </>
        )
    }
}
export default EnsInnoHighEffProp;