import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer6'

const bg = require('../../images/banner/banner1.png')

class OurbusinessReiEhWind extends Component {


    render() {
        return (
            <>
                <Header/>
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">WIND TURBINE</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="ourproduct">OUR PROJECT</Link></li>
                                        <li><Link to="ensinnoslider">ENERGY INNOVATION</Link></li>
                                        <li><Link to="eh">ENERGY HARVESTING</Link></li>
                                        <li>WIND TURBINE</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-block">
                        <div className="section-full content-inner">
                            <div className="container">
                                <div className="section-head text-center">

                                    <h2 className="title">Wind Turbine</h2>
                                    <div className="description">Innovative design of our wind turbine to achieve greater than theoretical maximum efficiency.</div>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <img src={require("../../images/imagewindturbine.png")} alt=""/>
                                    <br/>
                                    <br/>
                                    <blockquote><div className='description'>ENSERVs novel design of turbine blades, the efficiency was recorded up to 75% breaking the World’s record of 60% efficiency. The turbines are being developed and optimized to be suitable for each utility and purpose.</div></blockquote>
                                    <br/>
                                    <img src={require("../../images/imagewindleft.png")} alt=""/>
                                    <img src={require("../../images/imagewindright.png")} alt=""/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <img src={require("../../images/graphwindturbine.png")} alt=""/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <div className="description">Our wind turbine has more than twice efficiency than the Regen wind turbine.</div>
                                </div>
                            </div>
                    </div>
                </div>
                <Footer/>
                </div>
            </>
        )
    }
}
export default OurbusinessReiEhWind;