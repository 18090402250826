import React, { Component } from 'react';

class SlideEnsInnoHbs2 extends Component {


    render() {
        return (
            <>

                    <div className="page-content bg-white">
                        <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-content row">
                                    

                                    <div className="col-lg-12 col-md-12">
                                        <div className="hero-content" align="justify">
                                        <p>Sorption based heat pumping devices for the management of heat in both industrial and tertiary sectors are generating increasing interest throughout the world. These systems present many advantages compared to conventional systems as soon as they are driven with waste and/or renewable heat. We can provide heating as well as cooling and can be operated as heat transformer in order to increase the heat quality (temperature) of a given source. Also, they can be put in a multi-effect arrangement which allows to increase the system performance significantly.</p>
                                        <p>ENSERV is interested to develop a multi-effect sorption heat pump that is able to make use of medium to low temperature heat (130-400°C) for the integration into a complex and innovative energy system. The objective of the present work package is to calculate the maximum theoretical performance that can be achieved by the system for the defined operating conditions. Also, the auxiliary energy required for the fans and pumps shall be estimated according to assumptions made.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  

            </>
        )
    }
}
export default SlideEnsInnoHbs2;