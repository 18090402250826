import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header';
import Footer from '../layout/footer6';

var bnr3 = require('./../../images/banner/banner1.png');


const bg = require('../../images/overlay/brilliant.png')

class About1 extends Component {


    render() {
        return (
            <>

                <Header />

                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle text-center bg-pt" style={{ backgroundImage: "url(" + bnr3 + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry align-m text-center">
                                <h1 className="text-white">ABOUT US</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">HOME</Link></li>
                                        <li>ABOUT US</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-block">

                        {/* Chairman                              */}
                        <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-content row">

                                    <div className="col-lg-4 col-md-12 center">
                                        <img src={require("../../images/chairmanimg.png")} alt="" />
                                    </div>

                                    <div className="col-lg-8 col-md-12">

                                        <div className="hero-content">
                                            <h1 className="title align-items-center">Chairman Message</h1>
                                            <div className="description" align="justify">The vision of our company is to be the Ultimate Energy Solutions Provider. To achieve our vision, we put our resources into two core businesses; Clean Energy Innovation and Clean Energy Generation. <br />
                                                <br />
                                                1st Core: Clean Energy Innovation requires Research and Development in advanced energy technologies. Our multiple innovations create new technologies and knowledge which enables us to create our own novel solutions which we then provide to our projects and our partners.
                                                <br />
                                                <br />
                                                2nd Core: Clean Energy Generation is achieved by utilizing our key strengths and competencies in Strategic Planning, Analysis, Business Modelling and Engineering Design throughout the project life cycle together with our strategic partners in Finance, Consulting, Construction and O&M.
                                                <br />
                                                <br />
                                                We work hard to meet our CSR targets and support global Climate Change mitigation through the creation of clean energy and Eco-friendly solutions for all generations and our planet.
                                                <br />
                                                <br />
                                                We welcome new opportunities and look forward to working with you as our Ultimate Energy Solutions Partner.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Vision                                       */}
                        <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <blockquote>
                                    <h1 className="title align-items-center">Vision</h1>
                                    <br />
                                    <h4>“Create the World’s happiness and better livings with sustainability for human being”</h4>
                                </blockquote>
                            </div>
                        </div>

                        {/* Mission                                    */}
                        <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-content row">
                                    <div className="col-lg-4 col-md-12">
                                        <img src={require("../../images/wind.png")} alt="" />
                                    </div>
                                    <div className="col-lg-8 col-md-12">
                                        <div className="hero-content">
                                            <h1 className="title align-items-center">Mission</h1>
                                            <h2 className="title align-items-center">01</h2>
                                            <p className='description' align='justify'>Leading in sustainable Renewable Energy innovation (Storage/ Harvesting).</p>
                                            <h2 className="title align-items-center">02</h2>
                                            <p className='description' align='justify'>Leading in innovative Renewable Energy developer in the World.</p>
                                            <h2 className="title align-items-center">03</h2>
                                            <p className='description' align='justify'>One of the leading reliable and trustable Health & Life Security solutions.</p>
                                            <h2 className="title align-items-center">04</h2>
                                            <p className='description' align='justify'>Create SPV and corporate recognition which reflect the groups’ values to global.</p>
                                            <h2 className="title align-items-center">05</h2>
                                            <p className='description' align='justify'>Continuously developing human resources in accordance with our dynamically adaptive organization and sharing utmost benefits to every personnel in all dimensions.</p>
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-6 col-md-12">
                                    <img src={require("../../images/winds.png")} alt=""/>
                                </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        )
    }
}

export default About1;