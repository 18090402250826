import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer6'
import { Parallax } from 'react-parallax';
// import Partner from '../layout/partner'
// import Testimonial1 from '../element/testimonial1';

const bg = require('../../images/banner/banner1.png')
const bg9 = require('../../images/smartfarm1.png')
// const bg3 = require('../../images/background/bg1.jpg')

class EnsInnoSustainable extends Component {


    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">SUSTAINABLE AGRICULTURE</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">HOME</Link></li>
                                        <li><Link to="ourproduct">OUR PROJECTS</Link></li>
                                        <li><Link to="ensinnoslider">ENERGY INNOVATION</Link></li>
                                        <li>SUSTAINABLE AGRICULTURE</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-full bg-gray content-inner">
                        <div className='container'>
                            <h1 className="title align-items-center tocreate">SUSTAINABLE AGRICULTURE</h1>
                            <p align="justify">Immerse yourself in the future of farming with our sustainable agriculture initiatives. Discover how innovation harmonizes with agriculture, fostering environmental stewardship and resource efficiency. Our solutions empower farmers to cultivate resilient crops while nurturing a sustainable and flourishing ecosystem.</p>
                        </div>
                    </div>
                    <Parallax bgImage={bg9} strength={200} >
                        <div className="hero-section pt-50 home-bnr1">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="hero-content">
                                            <h1 className="title align-items-center">Smart Farming</h1>
                                            <h2><div className="description">Smart farming is the introduction of technology to facilitate operations and help reduce carbon dioxide emissions caused by various processes, both directly and indirectly. and also help farmers to have a better life</div></h2>
                                            <div className="buttons">
                                                {/* <Link to={"#"}>Read More</Link> */}
                                                <Link to={"about-1"}>About Us</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <img src={require("../../images/main-slider/slide77.png")} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Parallax>

                    <div className="section-full bg-gray content-inner">
                        <div className="container">
                            <div className="section-content row">
                                <div className="col-md-6 col-lg-3 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="icon-bx-wraper" data-name="01">
                                        <div className="icon-content">
                                            <img src={require("../../images/drone.png")} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="icon-bx-wraper" data-name="02">
                                        <div className="icon-content">
                                            <img src={require("../../images/elefarmtrac.png")} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="icon-bx-wraper" data-name="03">
                                        <div className="icon-content">
                                            <img src={require("../../images/solarwaterpump.png")} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="icon-bx-wraper" data-name="04">
                                        <div className="icon-content">
                                            <img src={require("../../images/sotellite.png")} alt="" />
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                </div>
                <Footer />




            </>
        )
    }
}
export default EnsInnoSustainable;