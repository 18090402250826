import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from '../layout/header';
import Footer from '../layout/footer6';

const bg = require('../../images/banner/banner1.png')

class EnsInnoSlider extends Component {

    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">ENERGY INNOVATION</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">HOME</Link></li>
                                        <li><Link to="ourproduct">OUR PROJECTS</Link></li>
                                        <li>ENERGY INNOVATION</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full content-inner bg-white">
                        <div className="container">
                            <h2>Discover a world of creative and sustainable energy solutions with us.</h2>
                            <p align="justify">Our commitment to Energy Innovation drives us to redefine how we generate, store, and use energy. From advnaces technologies to innovative approaches, we're shaping a cleaner and more efficient energy future. Join us on the journey towards a sustainable tomorrow! #EnergyInnovation #SustainableFuture ⚡🌿</p>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="/es"><img src={require("../../images/energyinnovation/energy_storage.jpg")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">
                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="/es">ENERGY STORAGE</Link></h4>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>Step into the future of sustainable power solutions...</p>
                                            </div>
                                            <div className="dlab-post-readmore">
                                                <Link to="/es" title="READ MORE" rel="bookmark" className="site-button btnhover16" target="_top">READ MORE
                                                    <i className="ti-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="/eh"><img src={require("../../images/energyinnovation/energy_harvesting.jpg")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">
                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="/eh">ENERGY HARVESTING</Link></h4>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>Immerse yourself in the evolution of energy utilization.</p>
                                            </div>
                                            <div className="dlab-post-readmore">
                                                <Link to="/eh" title="READ MORE" rel="bookmark" className="site-button btnhover16" target="_top">READ MORE
                                                    <i className="ti-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="/ee"><img src={require("../../images/energyinnovation/energy_enhancement.jpg")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">

                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="/ee">ENERGY ENHANCEMENT</Link></h4>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>Elevate your energy infrastructure with our transformative solutions.</p>
                                            </div>
                                            <div className="dlab-post-readmore">
                                                <Link to="/ee" title="READ MORE" rel="bookmark" className="site-button btnhover16" target="_top">READ MORE
                                                    <i className="ti-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="/ensinnosustainablematerials"><img src={require("../../images/energyinnovation/energy_sustainable.jpg")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">

                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="/ensinnosustainablematerials">SUSTAINABLE MATERIALS DEVELOPMENT</Link></h4>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>Explore a realm where sustainability meets functionality.</p>
                                            </div>
                                            <div className="dlab-post-readmore">
                                                <Link to="/ensinnosustainablematerials" title="READ MORE" rel="bookmark" className="site-button btnhover16" target="_top">READ MORE
                                                    <i className="ti-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="/ensinnorenewablesustainable"><img src={require("../../images/energyinnovation/energy_renewable.jpg")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">

                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="/ensinnorenewablesustainable">RENEWABLE SUSTAINABLE ENERGY</Link></h4>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p align="justify">Connect with the movement towards a cleaner energy future.</p>
                                            </div>
                                            <div className="dlab-post-readmore">
                                                <Link to="/ensinnorenewablesustainable" title="READ MORE" rel="bookmark" className="site-button btnhover16" target="_top">READ MORE
                                                    <i className="ti-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="/ensinnosustainable"><img src={require("../../images/energyinnovation/energy_agriculture.jpg")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">

                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="/ensinnosustainable">SUSTAINABLE AGRICULTURE</Link></h4>
                                            </div>
                                            <br />
                                            <div className="dlab-post-text">
                                                <p align="justify">Immerse yourself in the future of farming with our sustainable agriculture initiatives.</p>
                                            </div>
                                            <div className="dlab-post-readmore">
                                                <Link to="/ensinnosustainable" title="READ MORE" rel="bookmark" className="site-button btnhover16" target="_top">READ MORE
                                                    <i className="ti-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="#ensinnosmartenergyplatform"><img src={require("../../images/energyinnovation/energy_platform.png")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">

                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="ensinnosmartenergyplatform">SMART ENERGY PLATFORM</Link></h4>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p align="justify">Smart Energy Platform:  An integrated system that leverages...</p>
                                            </div>
                                            <div className="dlab-post-readmore">
                                                <Link to="ensinnosmartenergyplatform" title="READ MORE" rel="bookmark" className="site-button btnhover16" target="_top">READ MORE
                                                    <i className="ti-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default EnsInnoSlider;