import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from '../layout/header';
import Footer from '../layout/footer6';

const bg = require('../../images/banner/lithiumsulphurbattery.png')



class EnsInnoLsbSlider extends Component {

    render() {

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true
        };

        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">LITHIUM SULPHUR BATTERY</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">HOME</Link></li>
                                        <li><Link to="ourproduct">OUR PROJECT</Link></li>
                                        <li><Link to="ensinnoslider">ENERGY INNOVATION</Link></li>
                                        <li><Link to="es">ENERGY STORAGE</Link></li>
                                        <li>LITHIUM</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-full content-inner">
                        <div className="container">
                            <div className='section-content-row'>

                                <p className="tocreate" color="red">What is it?</p>
                                <p align="justify">In Li-ion batteries, the lithium ions are stored in active materials acting as stable host structures during charge and discharge. In lithium-sulfur (Li-S) batteries, there are no host structures. While discharging, the lithium anode is consumed and sulfur transformed into a variety of chemical compounds; during charging, the reverse process takes place.</p>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-6 col-md-12'>
                                        <img src={require("../../images/lithium.png")} alt="" />
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <blockquote><p align="justify">A more powerful,cheaper, and greener battery for the future.
                                            Li-s battery gives higher energy density than normal lithium-ion battery and has lighter weight. Due to very light active materials , which is suitable for utilities, especially in aviation and space industries. ENSERV has been developing a technique of Li-S based on solid-state electrolyte to overcome major drawbacks of the conventional liquid-based Li-S battery, bringing higher density energy, longer life cycles and higher self discharge.</p></blockquote>
                                    </div>
                                </div>
                                <div className="dlab-post-text">
                                    <p align="justify">A Li-S battery uses very light active materials: sulfur in the positive electrode and metallic lithium as the negative electrode. This is why its theoretical energy density is extraordinarily high: four times greater than that of lithium-ion. That makes it a good fit for the aviation and space industries.</p>
                                    <h4>ENSERV has selected and favoured the most promising Li-S technology based on solid state electrolyte.</h4>
                                    <p align="justify">This technical path brings very high energy density, long life and overcomes the main drawbacks of the liquid-based Li-S (limited life, high self discharge, …).</p>
                                </div>


                            </div>
                        </div>
                    </div>



                </div>
                <Footer />
            </>
        )
    }
}
export default EnsInnoLsbSlider;