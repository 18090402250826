import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header1'
import Footer from '../layout/footer'
// import Partner from '../layout/partner'
// import Testimonial1 from '../element/testimonial1';

const bg = require('../../images/banner/banner1.png')
// const bg2 = require('../../images/background/bg1.jpg')
// const bg3 = require('../../images/background/bg1.jpg')

class Apec2022 extends Component {

    render() {
        return (
            <>
                <Header/>
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">News</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/allnews">News</Link></li>
                                        <li>14-19 November 2022</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="section-full bg-gray content-inner">
                            <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-head text-center">
                                    
                                    <h2 className="title">Miracle menu from "ENSERV" at  "APEC 2022"</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-content row">
                                    <div className="col-md-12 col-lg-12 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                        <div className="icon-bx-wraper" data-name="01">
                                            <div className="icon-content">
                                                <div className="section-content row">
                                                    <div className="col-lg-6 col-md-12">
                                                        <img src={require("../../images/news/emfservedinapec2022/316139100_146211188159367_7848922480575779567_n.jpg")} alt="center"/>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <img src={require("../../images/news/emfservedinapec2022/301123.jpg")} alt="center"/>
                                                    </div>

                                                </div>

                                                <h2 className="title-ourservice">14-19 November 2022</h2>
                                                <p align="justify">"ENSERV" is truly honored to serve our "EMF (ENSERV M Food)" as the selected dish at the "APEC 2022" (Asia-Pacific Economic Cooperation 2022) held* at Queen Sirikit National Convention Center (QSNCC), Bangkok, Thailand between 14-19 November 2022.   "EMF" is the bread, which never let you gain weight.*  "ENSERV" always keens on creating innovations for sustainable human life.</p>
                                                <div className="col-lg-12 col-md-12">
                                                    <img src={require("../../images/EMF-logo.png")} alt="center"/>
                                                </div>
                                                
                                                <div className="section-content row">
                                                    <div className="col-lg-6 col-md-12">
                                                        <img src={require("../../images/news/emfservedinapec2022/301100.jpg")} alt="center"/>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <img src={require("../../images/news/emfservedinapec2022/300707.jpg")} alt="center"/>
                                                    </div>
                                                </div>
                                                <br/>
                                                {/* <div className="section-content row">
                                                    <div className="col-lg-6 col-md-12">
                                                        <img src={require("../../images/news/emfservedinapec2022/301132.jpg")} alt="center"/>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <img src={require("../../images/news/emfservedinapec2022/2142.jpg")} alt="center"/>
                                                    </div>
                                                    
                                                </div> */}
                                               
                                                
                                                

                                            </div>
                                         </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>                
                <Footer/>                
            </>
        )
    }
}
export default Apec2022;