import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header1'
import Footer from '../layout/footer'
// import Partner from '../layout/partner'
// import Testimonial1 from '../element/testimonial1';

const bg = require('../../images/banner/banner1.png')
// const bg2 = require('../../images/background/bg1.jpg')
// const bg3 = require('../../images/background/bg1.jpg')

class BGreenieGrin extends Component {

    render() {
        return (
            <>
                <Header/>
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">News</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/allnews">News</Link></li>
                                        <li>3nd November 2022</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="section-full bg-gray content-inner">
                            <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-head text-center">
                                    
                                    <h2 className="title">"ENSERV" Welcomes You with "Greenie Grin"</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-content row">
                                    <div className="col-md-12 col-lg-12 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                        <div className="icon-bx-wraper" data-name="01">
                                            <div className="icon-content">
                                                <div className="section-content row">
                                                    <div className="col-lg-12 col-md-12">
                                                        <img src={require("../../images/news/fwdenserv03_11_2022/S__64880643.jpg")} alt="center"/>
                                                    </div>
                                                    {/* <div className="col-lg-6 col-md-12">
                                                        <img src={require("../../images/news/fwdenserv03_11_2022/Screenshot 2022-11-03 143306.png")} alt="center"/>
                                                    </div> */}

                                                </div>

                                                <h2 className="title-ourservice">3nd November 2022</h2>
                                                <p align="justify">At "ENSERV" headquarters located at CAT Tower (Bang-rak), 24th floor, visitors will not see an information counter at an entrance of the office, but we are willing to welcome our guests with the "Greenie Grin".  The "Greenie Grin" is the ENSERV's stories of hydroponic salad vegetables, such as, red oak lettuce, green oak lettuce, butterhead lettuce, red coral lettuce and cos lettuce.  Although indoor planting is not new innovation, however, the objective of decorating this way, is to accentuate ENSERV's philosophy which is "Create Innovation for Sustainable Life" and more than that just to fill anyone's stomach with nutrients and fiber.  Any plan to visit “ENSERV” headquarters yet? bring some salad dressing with you and taste our “Greenie Grin” 😊</p>
                                                <div className="section-content row">
                                                    <div className="col-lg-6 col-md-12">
                                                        <img src={require("../../images/news/fwdenserv03_11_2022/2139.jpg")} alt="center"/>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <img src={require("../../images/news/fwdenserv03_11_2022/2140.jpg")} alt="center"/>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="section-content row">
                                                    <div className="col-lg-6 col-md-12">
                                                        <img src={require("../../images/news/fwdenserv03_11_2022/2141.jpg")} alt="center"/>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <img src={require("../../images/news/fwdenserv03_11_2022/2142.jpg")} alt="center"/>
                                                    </div>
                                                    
                                                </div>
                                               
                                                
                                                

                                            </div>
                                         </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>                
                <Footer/>                
            </>
        )
    }
}
export default BGreenieGrin;