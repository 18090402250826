import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from '../layout/header';
import Footer from '../layout/footer6';

const bg = require('../../images/banner/bnharvesting.png')

class EnsInnoColdFusion extends Component {

    render() {


        return (
            <>
                <Header />
                    <div className="page-content bg-white">
                        <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                            <div className="container">
                                <div className="dlab-bnr-inr-entry">
                                    <h1 className="text-white">COLD FUSION SYSTEM</h1>
                                    <div className="breadcrumb-row">
                                        <ul className="list-inline">
                                            <li><Link to="/">HOME</Link></li>
                                            <li><Link to ="ourproduct">OUR PROJECT</Link></li>
                                            <li><Link to ="ensinnoslider">ENERGY INNOVATION</Link></li>
                                            <li><Link to ="eh">ENERGY HARVESTING</Link></li>
                                            <li>CFS..</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section-full bg-gray content-inner">
                            <div className="container">
                            <h1 className="title align-items-center tocreate">Cold Fusion System (CFS)</h1>
                                <p align="justify">Enserv focuses on Cold Fusion Systems (CFS) due to the intriguing phenomena of excess heat generation observed in various systems over the years. Typically involving a transition metal or alloy loaded with hydrogen or deuterium, these systems have demonstrated instances where the output power significantly exceeds the input power used for heat generation, and such excess heat can't be easily explained by conventional electro-chemical or pure chemical reactions.</p>
                                <p align="justify">Over the past several decades, excess heat generation phenomena have been observed under different settings and in different systems. Generally, an excess heat generation system comprises a transition metal or alloy loaded with hydrogen or deuterium. In certain cases, and under certain conditions, the amount of output power significantly exceeded the amount of input power used for operating the heat generation system. In many of those reported cases, the  amount of excess heat generated couldn't be explained by electro-chemical or pure chemical reactions.</p>
                                <p align="justify">Exothermic reactions including fusion reaction, low energy nuclear reaction (LENR), and cold fusion usually generate most efficiently at a constant power level. However, the power demand always varies over operating cycle depending on the objective of power utilization. Moreover, a reactor containing the exothermic reactions need a long time period for activation. Coupling an exothermic reactor to a multi-thermal energy storage (multi-TES) system can significantly improve the viability of the reactions.</p>
                            </div>
                        </div>
                    </div>
                <Footer />
            </>
        )
    }
}
export default EnsInnoColdFusion;