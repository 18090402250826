import React, { Component } from 'react';

class SlideEnerInnoSib1 extends Component {

    render() {
        return (
            <>
                
                <div className="page-content bg-white">
                        <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-content row">
                                    
                                    <div className="col-lg-6 col-md-12">
                                        <div className="hero-content">
                                            <h1 className="title align-items-center tocreate">Sodium Ion Battery</h1>
                                            <p className="tocreate" color="red">What is it?</p>
                                            <p align="justify">Sodium-ion batteries have great promise. They’re energy dense, nonflammable, and operate well in colder temperatures, and sodium is cheap and abundant. Plus, sodium-based batteries will be more environmentally friendly and even less expensive than lithium-ion batteries are becoming now. Sodium-ion battery performance has been limited because of poor durability, but this is about to change for the better.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-12">
                                        <img src={require("../../images/sodiumIonBattery.png")} alt=""/>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>              
            </>
        )
    }
}
export default SlideEnerInnoSib1;