import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from '../layout/header';
import Footer from '../layout/footer6';

const bg = require('../../images/banner/bnharvesting.png')

class EnsInnoBuoyancy extends Component {

    render() {


        return (
            <>
                <Header />
                    <div className="page-content bg-white">
                        <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                            <div className="container">
                                <div className="dlab-bnr-inr-entry">
                                    <h1 className="text-white">BUOYANCY AND VELOCITY FLUX</h1>
                                    <div className="breadcrumb-row">
                                        <ul className="list-inline">
                                            <li><Link to="/">HOME</Link></li>
                                            <li><Link to ="ourproduct">OUR PROJECT</Link></li>
                                            <li><Link to ="ensinnoslider">ENERGY INNOVATION</Link></li>
                                            <li><Link to ="eh">ENERGY HARVESING</Link></li>
                                            <li>BVF..</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section-full bg-white content-inner">
                            <div className="container">
                                <h1 className="title align-items-center tocreate">Buoyancy and Velocity Flux (BVF)</h1>
                                <div className="section-content row">
                                    <div className="col-lg-6 col-md-12">
                                            <div className="hero-content">
                                                <p align="justify">Electric power generation by using Earth's gravity and the properties of water to create a circular circuit generates clean energy electricity.
                                                <br />Extraction of energy from chained buoys in a high-water tank travelling up due to buoyancy force and down due to gravity is transformed into mechanical and electrical energy. Input energy is minimized by using special hydro pumps in the system.
                                                </p>
                                            </div>

                                            <div className="contact-right">
                                                <Link to="#" className="site-button appointment-btn btnhover13">Learn More</Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <img src={require("../../images/buoyanccy.png")} alt=""/>
                                        </div>
                                 </div>
                            </div>
                        </div>
                    </div>
                <Footer />
            </>
        )
    }
}
export default EnsInnoBuoyancy;