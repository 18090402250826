import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer6'

var bnr3 = require('./../../images/banner/banner1.png');

class Ourproduct extends Component {


    render() {
        return (
            <>
                <Header />

                    <div className="page-content bg-white">
                        <div className="dlab-bnr-inr overlay-black-middle text-center bg-pt"  style={{ backgroundImage: "url(" + bnr3 + ")" }}>
                            <div className="container">
                                <div className="dlab-bnr-inr-entry align-m text-center">
                                    <h1 className="text-white">OUR PROJECTS</h1>
                                    <div className="breadcrumb-row">
                                        <ul className="list-inline">
                                            <li><Link to="/">HOME</Link></li>
                                            <li>OUR PROJECS</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section-full content-inner bg-white">
                            <div className="container">

                                <div className="row">
                                    <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                                        <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                            <div className="dlab-post-media dlab-img-effect">
                                                <Link to="/ensinnoslider"><img src={require("../../images/energyinnovation.png")} alt=""/></Link>
                                            </div>
                                            <div className="dlab-info p-a20 border-1 bg-white">
                                                <div className="dlab-post-title">
                                                    <h4 className="post-title"><Link to="/ensinnoslider">ENERGY INNOVATION</Link></h4>
                                                </div>
                                                <div className="dlab-post-text">
                                                <p>Discover a world of creative and sustainable energy solutions with us.</p>
                                                </div>
                                                <div className="dlab-post-readmore">
                                                    <Link to="/ensinnoslider" title="READ MORE" rel="bookmark" className="site-button btnhover16">READ MORE
                                                        <i className="ti-arrow-right"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
                                        <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                            <div className="dlab-post-media dlab-img-effect">
                                                <Link to="/lifehealth"><img src={require("../../images/lifehealth.jpg")} alt=""/></Link>
                                            </div>
                                            <div className="dlab-info p-a20 border-1 bg-white">

                                                <div className="dlab-post-title">
                                                    <h4 className="post-title"><Link to="/lifehealth">LIFE & HEALTH SOLUTION</Link></h4>
                                                </div>
                                                <div className="dlab-post-text">
                                                <p>"Embark on a transformative journey with Enserv's cutting-edge...</p>
                                                </div>
                                                <div className="dlab-post-readmore">
                                                    <Link to="/lifehealth" title="READ MORE" rel="bookmark" className="site-button btnhover16">READ MORE
                                                        <i className="ti-arrow-right"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        {/* <div className="content-block">
                                <div className="section-full bg-gray content-inner">
                                    <div className="container">
                                        <div className="section-content row">


                                            <div className="col-lg-6 col-md-12">
                                                <img src={require("../../images/energyinnovation.png")} alt=""/>
                                            </div>

                                            <div className="col-lg-6 col-md-12">
                                                <h2><div classname="tocreate" align='center'>ENERGY INNOVATION</div></h2>
                                                <div className="contact-right" align='center'>
                                                        <Link to="/ensinnoslider" className="site-button appointment-btn btnhover13">Learn More</Link>
                                                </div>

                                            </div>
                                         </div>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                         <div className="section-content row">


                                            <div className="col-lg-6 col-md-12">
                                                <h2><div classname="tocreate" align='center'>LIFE & HEALTH SOLUTION</div></h2>
                                                <div className="contact-right" align='center'>
                                                        <Link to="/ensinnoslider" className="site-button appointment-btn btnhover13">Learn More</Link>
                                                </div>
                                            </div>


                                            <div className="col-lg-6 col-md-12">
                                                <img src={require("../../images/lifehealth.png")} alt=""/>
                                            </div>


                                         </div>
                                         <br></br>
                                         <br></br>
                                    </div>
                                </div>
                        </div> */}
                    </div>
                <Footer />
            </>
        )
    }
}

export default Ourproduct;