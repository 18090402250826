import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer6'
// import Partner from '../layout/partner'
// import Testimonial1 from '../element/testimonial1';

const bg = require('../../images/banner/banner1.png')
// const bg2 = require('../../images/background/bg1.jpg')
// const bg3 = require('../../images/background/bg1.jpg')

class NewsIntelectual extends Component {

    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">"Intelectual Property for creating Business's values "</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/newsall">News</Link></li>
                                        <li>21 September 2023</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                        <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-head text-center">

                                    <h2 className="title">"Intelectual Property for creating Business's values"</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                        <div className="container">
                            <div className="section-content row">
                                <div className="col-md-12 col-lg-12 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="icon-bx-wraper" data-name="01">
                                        <div className="icon-content">
                                            <div className="section-content row">
                                                <div className="col-lg-6 col-md-12">
                                                    <img src={require("../../images/news/Intelectual/S__29900814.jpg")} alt="center" />
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <img src={require("../../images/news/Intelectual/S__29900820.jpg")} alt="center" />
                                                </div>
                                            </div>
                                            <h2 className="title-ourservice">21 September 2023</h2>
                                            <p align="justify">ENSERV Holding has recently  arranged the skill-based training on "Intelectual Property for creating Business's values " on September 21 ,2023 , the training  has been conducted by senior IP Examiner from  Department of Intellectual Property ,Ministry of Commerce ,a group of 20 R&D engineering , scientist  and business development from ENSRVE  Holding and Affiliated companies the attended the training</p>
                                            <div className="section-content row">
                                                <div className="col-lg-6 col-md-12">
                                                    <img src={require("../../images/news/Intelectual/S__29900822_0.jpg")} alt="center" />
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <img src={require("../../images/news/Intelectual/S__29900828.jpg")} alt="center" />
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default NewsIntelectual;