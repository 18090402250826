import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer6'
// import Partner from '../layout/partner'
// import Testimonial1 from '../element/testimonial1';

const bg = require('../../images/banner/banner1.png')
// const bg2 = require('../../images/background/bg1.jpg')
// const bg3 = require('../../images/background/bg1.jpg')

class News1Mongo extends Component {

    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">News</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/newsall">News</Link></li>
                                        <li>5-7 September 2022</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                        <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-head text-center">
                                    <p>RENEWABLE ENERGY INNOVATION</p>
                                    <h2 className="title">Enserv Group attends "The First trade cooperation conference in history between Thailand and Mongolia"</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                        <div className="container">
                            <div className="section-content row">
                                <div className="col-md- col-lg-12 col-sm-12 service-box style3 wow fadeInUp">
                                    <div className="icon-bx-wraper" data-name="01">
                                        <div className="icon-content">
                                            <img src={require("../../images/news/mongo/mongo2.png")} alt="" />
                                            <h2 className="title-ourservice">5-7 September 2022</h2>
                                            <p>During 5-7 September 2022, the Deputy Prime Minister and Minister of Commerce of the Kingdom of Thailand, Mr. Jurin Laksanavisit, made an official visit to Mongolia from the invitation of the Minister of Foreign Affairs of Mongolia. During the visit of The Minister of Foreign Affairs of Mongolia, B.Battsetseg and the Deputy Prime Minister and Minister of Trade of the Kingdom of Thailand, Mr. Jurin Laksanavisit , signed the 'Memorandum of Understanding on the Establishment of a Joint Committee on Trade between the Ministry of Foreign Affairs of Mongolia and the Ministry of Commerce of the Kingdom of Thailand' and held the first committee meeting.</p>
                                            <p>This conference was the first business meeting in the history between Thailand and Mongolia, where more than 30 companies of both countries were represented in the mining sector for example, Banpu Public Company Limited, Akaliko Global, Bumrungrad International Hospitals and Enserv Group, which has invested more than US$1 billion in Mongolia joined in the negotiations as well.</p>
                                            <p>Currently, there are at least 4 large Thai entrepreneurs who have invested here, namely Banpu Company, Sermsang Power Corporation Plc., Enserv UB llc., and Bumrungrad Hospital, with an investment value of approximately 36,000 million baht. This includes investing in solar power plants and selling them to the Mongolian government.</p>
                                            <p>Following its establishment, JTC expects to increase its commercial value from $55 million up to $100 million within the next five years or by 2027. It is also hoped that the value of investment between the two countries will increase to 1.5. billion.</p>
                                            <p><img src={require("../../images/news/mongo/mongo.png")} alt="" /></p>
                                            <p><img src={require("../../images/news/mongo/mongo6.png")} alt="" /></p>
                                            <p><img src={require("../../images/news/mongo/mongo7.png")} alt="" /></p>
                                            <div>
                                                <p>
                                                    <a href={"https://www.prachachat.net/economy/news-1039086"} target={"_blank"}>https://www.prachachat.net/economy/news-1039086</a><br/>
                                                    <a href={"https://montsame.mn/en/read/303397"} target={"_blank"}>https://montsame.mn/en/read/303397</a><br/>
                                                    <a href={"https://www.prachachat.net/economy/news-1038701"} target={"_blank"}>https://www.prachachat.net/economy/news-1038701</a>
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default News1Mongo;