import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header1'
import Footer from '../layout/footer'

const bg = require('../../images/banner/banner1.png')

class LifeHealthEmf extends Component {


    render() {
        return (
            <>
                <Header/>
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">LIFE & HEALTH SOLUTION</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="ourproduct">OUR PROJECTS</Link></li>
                                        <li><Link to="ourproduct-life">LIFE & HEALTH SOLUTION</Link></li>
                                        <li>EMF</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="section-full bg-gray content-inner">

                            <div className="container">
                                <h1 className='tocreate' align="center">Food and Sweets</h1>
                                <br/>
                                <div className="section-content row">
                                        <div className="col-md-6 col-lg-3 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                            <div className="icon-content">
                                                <img src={require("../../images/life&health/emf2.png")} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-3 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                            <div className="icon-content">
                                                <img src={require("../../images/life&health/emf3.png")} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-3 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                            <div className="icon-content">
                                                <img src={require("../../images/life&health/emf4.png")} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-3 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                            <div className="icon-content">
                                                <img src={require("../../images/life&health/emf5.png")} alt=""/>
                                            </div>
                                        </div>
                                </div>
                                    <br/>
                                    <div className='icon-content'>
                                        <img src={require("../../images/life&health/whatis.png")} alt=""/>
                                    </div>
                                    <br/>
                                    <div>
                                        <h1 className='tocreate'>Enserv Miracal Food</h1>
                                    </div>

                                    <br/>
                                    <h4 className='description'>
                                        <ul>
                                            <li>Good Food, Great Taste</li>
                                            <li>High Carb and Ultra Low Cal</li>
                                            <li>Function food</li>
                                            <li>Improve human’s life</li>
                                        </ul>
                                    </h4>
                                    <br/>
                                    <div className="section-content row center">
                                        <div className="col-md-6 col-lg-3 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                            <div className="dlab-post-media dlab-img-effect zoom">
                                                <Link to="ourproduct-life-emf-lcf" target="_top"><img src={require("../../images/life&health/low-calorie-flour.png")} alt=""/></Link>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-3 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                            <div className="dlab-post-media dlab-img-effect zoom">
                                                <Link to="ourproduct-life-emf-lcs" target="_top"><img src={require("../../images/life&health/lowcaloriesugar.png")} alt=""/></Link>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-3 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                            <div className="dlab-post-media dlab-img-effect zoom">
                                                <img src={require("../../images/life&health/nexttoemf.png")} alt=""/>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='icon-content'>
                                        <img src={require("../../images/life&health/foodleft1.png")} alt=""/>
                                    </div>
                                    <br/>
                                    <br/>
                                    <h1 className='tocreate' align="center">Restaurant</h1>
                                    <div className='icon-content'>
                                        <img src={require("../../images/life&health/down2earth.png")} alt=""/>
                                    </div>
                            </div>
                        </div>


                </div>
                <Footer/>
            </>
        )
    }
}
export default LifeHealthEmf;