import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer6'

const bg = require('../../images/banner/banner1.png')

class OurbusinessReiEhProp extends Component {


    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className='container'>
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">HYDRO TURBINE</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="ourproduct">OUR PROJECT</Link></li>
                                        <li><Link to="ensinnoslider">ENERGY INNOVATION</Link></li>
                                        <li><Link to="eh">ENERGY HARVESTING</Link></li>
                                        <li>HYDRO TURBINE</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full content-inner-2 bg-primary overlay-primary-middle">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 service-main-title text-white text-center">
                                    <div className="section-head text-center">

                                        <h2 className="title">HYDRO TURBINE</h2>
                                        <h3>High Efficiency Wind Propeller and Turbine.</h3>
                                        <div className="description">Innovative design of our wind turbine to achieve greater than theoretical maximum efficiency.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-full">
                        <div className="row spno about-industry">
                            <div className="col-lg-8">
                                <img src={require("../../images/properell.png")} alt="" className="img-cover" />
                            </div>
                            <div className="col-lg-4 bg-white">
                                <div className="service-box style2">
                                    <div>
                                        <h2 className="title text-black"><span>The Technology</span> <br />Works</h2>
                                        <p align="justify">The propeller and turbine were invented for thousands year on the Archimedes' screw principle which is simple and straightforward. However, this principle has reached the maximum efficiency limit. Enserv and partner had developed a radicle concept the redesign the principle by using golden ratio and natural mimicking which resulted in a unique blade's geometry with leading edge and trailing edge unlike Archimedes' screw. The new design had been proven by computer simulation and prototype tested on wind tunnel which show 30%-40% better efficiency compare to industrial fan design.</p>
                                        <Link to="/contact-4" className="site-button outline outline-2" target="_top">ABOUT US</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <br />
                                <br />
                                <br />
                                <img src={require("../../images/windgen1.png")} alt="" />
                            </div>

                            <div className="col-lg-4 bg-secondry text-white">
                                <div className="service-box style2">
                                    <div>
                                        <h2 className="title"><span>ENSERV Wind Turbine</span> <br />Gen 1.</h2>
                                        <p align="justify">Our ”Wind Turbine Research Center” located in Samae Sarn, Chon Buri</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <img src={require("../../images/windsamae.png")} alt="" className="img-cover" />
                            </div>
                            <div className="col-lg-4 bg-primary text-white">
                                <div className="service-box style2">
                                    <div>
                                        <h2 className="title"><span>Aviation</span></h2>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        <Link to="/contact-4" className="site-button outline white outline-2" target="_top">ABOUT US</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 bg-white">
                                <div className="service-box style2">
                                    <div>
                                        <h2 className="title"><span>Drone</span></h2>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        <Link to="/contact-4" className="site-button outline white outline-2" target="_top">ABOUT US</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 bg-primary text-white">
                                <div className="service-box style2">
                                    <div>
                                        <h2 className="title text-black"><span>Hydro</span></h2>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        <Link to="/contact-4" className="site-button outline white outline-2" target="_top">ABOUT US</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 bg-white">
                                <div className="service-box style2">
                                    <div>
                                        <h2 className="title text-black"><span>Cooling Blade</span></h2>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                        <Link to="/contact-4" className="site-button outline outline-2" target="_top">ABOUT US</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <img src={require("../../images/our-work/oilgas/pic5.jpg")} alt="" className="img-cover" />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default OurbusinessReiEhProp;