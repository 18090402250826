import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header1'
import Footer from '../layout/footer'
// import Partner from '../layout/partner'
// import Testimonial1 from '../element/testimonial1';

const bg = require('../../images/banner/banner1.png')
// const bg2 = require('../../images/background/bg1.jpg')
// const bg3 = require('../../images/background/bg1.jpg')

class AllVideo extends Component {

    render() {
        return (
            <>
                <Header/>
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">News</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li>News</li>
                                        <li>10 SEPTEMBER 2020</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="section-full bg-gray content-inner">
                            <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-head text-center">
                                    <p>RENEWABLE ENERGY INNOVATION</p>
                                    <h2 className="title">A Swansea University research team from the College of Engineering are to form a new partnership with major global energy solutions provider ENSERV POWER.</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-content row">
                                    <div className="col-md- col-lg-12 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                        <div className="icon-bx-wraper" data-name="01">
                                            <div className="icon-content">
                                                <img src={require("../../images/news/news1.png")} alt=""/>
                                                <h2 className="title-ourservice">10 SEPTEMBER 2020</h2>
                                                <p>A Swansea University research team from the College of Engineering are to form a new partnership with major global energy solutions provider ENSERV POWER.</p>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Est, natoque quisque ultrices blandit eu, volutpat. Integer condimentum est, lacus, orci, leo sed. </p>
                                                <div className="contact-right">
                                                <Link to="#" className="site-button appointment-btn btnhover13">Read More</Link>
                                                </div>
                                            </div>
                                         </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>                
                <Footer/>                
            </>
        )
    }
}
export default AllVideo;