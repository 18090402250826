import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer6'
// import Partner from '../layout/partner'
// import Testimonial1 from '../element/testimonial1';

const bg = require('../../images/banner/banner1.png')
// const bg2 = require('../../images/background/bg1.jpg')
// const bg3 = require('../../images/background/bg1.jpg')

class NewsSportday24 extends Component {

    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">Enserv Sport Day 2024</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/newsall">News</Link></li>
                                        <li>Feb. 29,2024</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                        <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="title">Enserv Sport Day 2024 :<br/> A Global Celebration of Unity and Sustainability<br/>@Rama Garden</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                        <div className="container">
                            <div className="section-content row">
                                <div className="col-md-12 col-lg-12 col-sm-12 service-box style3 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="icon-bx-wraper" data-name="01">
                                        <div className="icon-content">
                                            <div className="section-content row wraper" align="center">
                                                <div className="col-lg-12 col-md-12">
                                                    <img src={require("../../images/news/sport24/IMG_0662.jpg")} alt="center" />
                                                </div>
                                            </div>
                                            <br/>
                                            <h2 className="title-ourservice">Feb. 29,2024</h2>
                                            <div className='indent-50'>
                                                <p align="justify">Bangkok, Thailand – Enserv Headquarters was abuzz with excitement as the company hosted its first-ever international staff gathering during the Enserv Sport Day 2024. Colleagues from overseas joined the event, setting the stage for a day of camaraderie, team building, and a shared commitment to a sustainable future.</p>
                                            </div>
                                            <br/>
                                            <div className="section-content row">
                                                <div className="col-lg-6 col-md-12">
                                                    <img src={require("../../images/news/sport24/IMG_0003.jpg")} alt="center" />
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <img src={require("../../images/news/sport24/IMG_0004.jpg")} alt="center" />
                                                </div>
                                            </div>
                                            <br />
                                            <div className='indent-50'>
                                                <p align="justify">This year's Sport Day went beyond the traditional aim of fostering team spirit; it served as an icebreaker for staff from various nations who will collaborate closely on upcoming R&D projects. The focus on forging connections laid the groundwork for innovative solutions aimed at improving lives worldwide.</p>
                                            </div>
                                            <br/>
                                            <div className="section-content row">
                                                <div className="col-lg-6 col-md-12">
                                                    <img src={require("../../images/news/sport24/IMG_0005.jpg")} alt="center" />
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <img src={require("../../images/news/sport24/IMG_0006.jpg")} alt="center" />
                                                </div>
                                            </div>
                                            <br/>
                                            <div className="section-content row">
                                                <div className="col-lg-6 col-md-12">
                                                    <img src={require("../../images/news/sport24/IMG_0151.jpg")} alt="center" />
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <img src={require("../../images/news/sport24/IMG_0164.jpg")} alt="center" />
                                                </div>
                                            </div>
                                            <br/>
                                            <div className='indent-50'>
                                                <p align="justify">In a bold move towards sustainability, Enserv introduced sport polo shirts made from recycled PET material – eight plastic bottles were used for each shirt. This initiative not only promoted eco-conscious choices but also symbolized the company's dedication to reducing environmental impact.</p>
                                                <p>The highlight of the day was the announcement of upcoming projects, including the launch of "Enserv M Food." This healthy breakfast option will be available throughout the day, serving as both a nutritious start and a delightful dessert, providing staff with the energy needed for various sports activities.</p>
                                            </div>

                                            <br/>

                                            <div className="section-content row">
                                                <div className="col-lg-6 col-md-12">
                                                    <img src={require("../../images/news/sport24/IMG_0170.jpg")} alt="center" />
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <img src={require("../../images/news/sport24/IMG_0221.jpg")} alt="center" />
                                                </div>
                                            </div>
                                            <br/>

                                            <div className='indent-50'>
                                                <p align="justify">As the sun set, the festivities continued with an evening party. The celebration included a special birthday cake for Chairman [K.Jim, Tanachat Pochana], crafted from EMF flour, ensuring a low-calorie, high-protein treat for all. This thoughtful gesture showcased Enserv's commitment to incorporating sustainability into every aspect of its operations, even down to the moments of celebration.</p>
                                            </div>
                                            <br/>
                                            <div className="section-content row">
                                                <div className="col-lg-6 col-md-12">
                                                    <img src={require("../../images/news/sport24/IMG_0757-2.jpg")} alt="center" />
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <img src={require("../../images/news/sport24/IMG_0759-2.jpg")} alt="center" />
                                                </div>
                                            </div>
                                            <br/>
                                            <div className='indent-50'>
                                                <p align="justify">Enserv Sport Day 2024 not only marked a significant milestone in global team collaboration but also showcased the company's unwavering dedication to a sustainable and healthy future.</p>
                                            </div>
                                            <br/>
                                            <div className="section-content row">
                                                <div className="col-lg-6 col-md-12">
                                                    <img src={require("../../images/news/sport24/IMG_0837.jpg")} alt="center" />
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <img src={require("../../images/news/sport24/IMG_0853.jpg")} alt="center" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default NewsSportday24;