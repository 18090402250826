import React, { Component } from 'react';
import Header from '../layout/header'
import Footer from '../layout/footer6'
import { Link } from 'react-router-dom';

const bg = require('../../images/banner/banner1.png')

class EnsInnoRenewableSustainable extends Component {


    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">RENEWABLE SUSTAINABLE ENERGY</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">HOME</Link></li>
                                        <li><Link to="ourproduct">OUR PROJECTS</Link></li>
                                        <li><Link to="ensinnoslider">ENERGY INNOVATION</Link></li>
                                        <li>RENEWABLE SUSTAINABLE ENERGY</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hero-section pt-50 home-bnr1">
                        <div className="container" align='center'>
                            <h2>Connect with the movement towards a cleaner energy future.</h2>
                            <p align="justify">Our dedication to renewable energy sources spans a diverse portfolio, from solar to emerging technologies, shaping a sustainable energy ecosystem for generations to come.</p>
                            <img src={require("../../images/renewmapmo.png")} alt="" />
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default EnsInnoRenewableSustainable;