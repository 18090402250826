import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer6'

const bg = require('../../images/banner/banner1.png')

class LifeHealth extends Component {


    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">LIFE & HEALTH SOLUTIONS</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">HOME</Link></li>
                                        <li><Link to="ourproduct">OUR PROJECTS</Link></li>
                                        <li>LIFE & HEALTH SOLUTIONS</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full content-inner bg-white">
                        <div className="container">
                        <h2>"Embark on a transformative journey with Enserv's cutting-edge innovations in Food and Medical technologies,</h2>
                            <p align="justify">seamlessly integrated under the brand of 'EMF' for food innovations and 'Encare' for Health innovations. Noteworthy projects such as Enwatch and Encare for CCTV, part of the overarching 'Watch your Back' Project, showcase our commitment to holistic well-being. These advancements aim to uplift your quality of life, providing revolutionary solutions that reshape the way we live. From pioneering nutritional breakthroughs to state-of-the-art medical technologies, Enserv's innovations are designed to enhance well-being and promote healthier lifestyles. Join us on the path to a better, more fulfilling life through the power of Enserv's pioneering advancements in food and medical sciences."</p>

                            <div className="row">
                                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="#"><img src={require("../../images/life&health/emf10.jpg")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">
                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="/es">EMF</Link></h4>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>Food innovations that make human beings more sustainable in their entire lives. Health and quality of life including saving the world.</p>
                                            </div>
                                            <div className="dlab-post-readmore">
                                                <p><a href={"https://www.enservmiraclefood.com/about-emf/"} target={"_blank"} className="site-button appointment-btn btnhover13">READ MORE</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="/lifehealth-encare"><img src={require("../../images/encare/encare1.png")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">

                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="/lifehealth-encare">ENCARE</Link></h4>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>Innovations that help facilitate human life in terms of disease, physical health, safety and human livelihood.</p>
                                            </div>
                                            <div className="dlab-post-readmore">
                                                <p>
                                                    <Link to="/lifehealth-encare" title="READ MORE" rel="bookmark" className="site-button btnhover16">READ MORE</Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default LifeHealth;