import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from '../layout/header';
import Footer from '../layout/footer6';

const bg = require('../../images/banner/bnharvesting.png')

class EnsInnoBe extends Component {

    render() {


        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">BIO-ENERGY</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/">HOME</Link></li>
                                        <li><Link to="ourproduct">OUR PROJECTS</Link></li>
                                        <li><Link to="ensinnoslider">ENERGY INNOVATION</Link></li>
                                        <li><Link to="eh">ENERGY HARVESTING</Link></li>
                                        <li>BIO-ENERGY</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-full bg-gray content-inner">
                        <div className="container">
                            <h1 className="title align-items-center tocreate">Bio-Energy</h1>
                            <p align="justify">Over the past several decades, excess heat generation phenomena have been observed under different settings and in different systems. Generally, an excess heat generation system comprises a transition metal or alloy loaded with hydrogen or deuterium. In certain cases, and under certain conditions, the amount of output power significantly exceeded the amount of input power used for operating the heat generation system. In many of those reported cases, the  amount of excess heat generated couldn't be explained by electro-chemical or pure chemical reactions.</p>
                            <p align="justify">Exothermic reactions including fusion reaction, low energy nuclear reaction (LENR), and cold fusion usually generate most efficiently at a constant power level. However, the power demand always varies over operating cycle depending on the objective of power utilization. Moreover, a reactor containing the exothermic reactions need a long time period for activation. Coupling an exothermic reactor to a multi-thermal energy storage (multi-TES) system can significantly improve the viability of the reactions.</p>
                            <blockquote><p className='tocreate'>Our farming practices aimed to reduce carbon footprint from traditional farming in halve.</p></blockquote>

                        </div>
                        <br />
                        <br />
                        <br />
                        <br />

                        <div className='container'>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="#"><img src={require("../../images/oilplamtrunk.png")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">
                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="#">OIL PALM</Link></h4>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>Thailand is third largest palm oil producer, but it accounts for only 3.9% of global production. So, unlike Indonesia and Malaysia, Thailand has little influence on global palm oil prices.</p>
                                            </div>
                                            <div className="dlab-post-readmore">
                                                <Link to="#" title="READ MORE" rel="bookmark" className="site-button btnhover16">READ MORE
                                                    <i className="ti-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                                    <div className="blog-post blog-grid blog-rounded blog-effect1 fly-box">
                                        <div className="dlab-post-media dlab-img-effect">
                                            <Link to="#"><img src={require("../../images/sorghum.png")} alt="" /></Link>
                                        </div>
                                        <div className="dlab-info p-a20 border-1 bg-white">
                                            <div className="dlab-post-title">
                                                <h4 className="post-title"><Link to="#">SUPER SORGHM</Link></h4>
                                            </div>
                                            <div className="dlab-post-text">
                                                <p>A fast growing plant with a wide range of yields. We therefore do research and research to bring this kind of pitch to make bio mass in electricity generation from natural energy.</p>
                                            </div>
                                            <div className="dlab-post-readmore">
                                                <Link to="#" title="READ MORE" rel="bookmark" className="site-button btnhover16">READ MORE
                                                    <i className="ti-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default EnsInnoBe;