import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';



class Header extends Component {

    componentDidMount() {

        var searchBtn = document.getElementById("quik-search-btn")
        var searchPopup = document.querySelector(".dlab-quik-search")
        var closeBtn = document.getElementById("quik-search-remove")

        searchBtn.addEventListener('click',function(){
            searchPopup.style.display = "block"
            searchPopup.classList.add("On")
        })

        closeBtn.addEventListener('click',function(){
            searchPopup.style.display = "none"
            searchPopup.classList.remove("On")
        })

        // sidebar open/close

        var btn = document.querySelector('.navicon');
        var nav = document.querySelector('.header-nav');

        function toggleFunc() {
            btn.classList.toggle("open");
            nav.classList.toggle("show");
        }

        btn.addEventListener('click', toggleFunc);


        // Sidenav li open close
        var navUl = [].slice.call(document.querySelectorAll('.header-nav > ul > li'));
        for (var y = 0; y < navUl.length; y++) {
            navUl[y].addEventListener('click', function () { checkLi(this) });
        }

        function checkLi(current) {
            const active = current.classList.contains("open")
            navUl.forEach(el => el.classList.remove('open'));
            //current.classList.add('open');

            if(active){
                current.classList.remove('open')
                console.log("active")
            } else{
                current.classList.add('open');
                console.log("close")
            }
        }

    }
    render() {

        return (
            <>
                <header className="site-header mo-left header">

                    <Sticky innerZ={999} enabled={true}>
                        <div className="sticky-header main-bar-wraper navbar-expand-lg">
                            <div className="main-bar clearfix ">
                                <div className="container clearfix">

                                    <div className="logo-header mostion">
                                        <Link to={'/'} target="_top"><img src={require('../../images/logo.png')} alt="" /></Link>
                                    </div>

                                    <button className="navbar-toggler navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </button>

                                    <div className="extra-nav">
                                        <div className="extra-cell">
                                            <button id="quik-search-btn" type="button" className="site-button-link"><i className="la la-search"></i></button>
                                        </div>
                                    </div>

                                    <div className="dlab-quik-search ">
                                        <form action="#">
                                            <input name="search" value="" type="text" className="form-control" placeholder="Type to search" />
                                            <span id="quik-search-remove"><i className="ti-close"></i></span>
                                        </form>
                                    </div>

                                    <div class={`header-nav navbar-collapse collapse justify-content-end`} id="navbarNavDropdown">
                                        <div className="logo-header d-md-block d-lg-none">
                                            <Link to={'/'} target="_top"><img src={require('../../images/logo.png')} alt="" /></Link>
                                        </div>
                                        <ul className="nav navbar-nav">
                                            <li className="has-mega-menu"> <Link to={'/about-1'} target="_top">ABOUT US</Link></li>
                                            <li className="has-mega-menu"> <Link to={'/sustainability'} target="_top">SUSTAINABILITY</Link></li>
                                            <li>
                                                <Link to="ourproduct">OUR BUSINESS<i className="fa fa-chevron-down"></i></Link>
                                                <ul className="sub-menu">
                                                    <li>
                                                        <Link to='ensinnoslider'>ENERGY INNOVATION &raquo;</Link>
                                                            <ul className="sub-menu">
                                                                <li><Link to={'/es'}>ENERGY STORAGE</Link></li>
                                                                <li><Link to={'/eh'}>ENERGY HARVESTING</Link></li>
                                                                <li><Link to={'/ee'}>ENERGY ENHANCEMENT</Link></li>
                                                                <li><Link to={'/ensinnosustainablematerials'}>SUSTAINABLE MATERIALS DEVELOPMENT</Link></li>
                                                                <li><Link to={'/ensinnorenewablesustainable'}>RENEWABLE SUSTAINABLE ENERGY</Link></li>
                                                                <li><Link to={'/ensinnosustainable'}>SUSTAINABLE AGRICULTURE</Link></li>
                                                                <li><Link to={'/ensinnosmartenergyplatform'}>SMART ENERGY PLATFORM</Link></li>
                                                            </ul>
                                                        </li>
                                                    <li><Link to='lifehealth'>LIFE & HEALTH SOLUTIONS &raquo;</Link>
                                                        <ul className='sub-menu'>
                                                            <li><a href={"https://www.enservmiraclefood.com/about-emf/"} target={"_blank"}>EMF</a></li>
                                                            <li><Link to={'/lifehealth-encare'}>ENCARE</Link></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>

                                            <li>
                                                <Link to="#">SUBSIDIARIES<i className="fa fa-chevron-down"></i></Link>
                                                <ul className="sub-menu">
                                                    <li><a href={"https://www.enserv.energy"} target={"_blank"}>ENSERV AUSTRALIA</a></li>
                                                    <li><a href={"https://www.equatorsolar.co.th"} target={"_blank"}>EQUATOR SOLAR</a></li>
                                                </ul>
                                            </li>


											<li className="has-mega-menu">
												<Link to={'newsall'}>NEWS</Link>
												{/* <ul className="mega-menu">
													<li> <Link to={'#'}>Blog</Link>
														<ul>
															<li><Link to={'/blog-half-img'}>Half image</Link></li>
															<li><Link to={'/blog-half-img-sidebar'}>Half image sidebar</Link></li>
															<li><Link to={'/blog-half-img-left-sidebar'}>Half image sidebar left</Link></li>
															<li><Link to={'/blog-large-img'}>Large image</Link></li>
														</ul>
													</li>
													<li> <Link to={'#'}>Blog</Link>
														<ul>
															<li><Link to={'blog-large-img-sidebar'}>Large image sidebar</Link></li>
															<li><Link to={'blog-large-img-left-sidebar'}>Large image sidebar left</Link></li>
															<li><Link to={'blog-grid-2'}>Grid 2</Link></li>
															<li><Link to={'blog-grid-2-sidebar'}>Grid 2 sidebar</Link></li>
														</ul>
													</li>
													<li> <Link to={'#'}>Blog</Link>
														<ul>
															<li><Link to={'blog-grid-2-sidebar-left'}>Grid 2 sidebar left</Link></li>
															<li><Link to={'blog-grid-3'}>Grid 3</Link></li>
															<li><Link to={'blog-grid-3-sidebar'}>Grid 3 sidebar</Link></li>
															<li><Link to={'blog-grid-3-sidebar-left'}>Grid 3 sidebar left</Link></li>
														</ul>
													</li>
													<li> <Link to={'#'}>Blog</Link>
														<ul>
															<li><Link to={'blog-grid-4'}>Grid 4</Link></li>
															<li><Link to={'blog-single'}>Single</Link></li>
															<li><Link to={'blog-single-sidebar'}>Single sidebar</Link></li>
															<li><Link to={'blog-single-left-sidebar'}>Single sidebar right</Link></li>
														</ul>
													</li>
												</ul> */}
											</li>
											<li className="has-mega-menu"> <Link to={'career'}>JOIN US</Link>
												{/* <ul className="mega-menu">
													<li><Link to={'#'}>Element</Link>
														<ul>
															<li><Link to={'shortcode-buttons'}><i className="ti-mouse"></i> Buttons</Link></li>
															<li><Link to={'shortcode-icon-box-styles'}><i className="ti-layout-grid2"></i> Icon box styles</Link></li>
															<li><Link to={'shortcode-pricing-table'}><i className="ti-layout-grid2-thumb"></i> Pricing table</Link></li>
															<li><Link to={'shortcode-team'}><i className="ti-user"></i> Team</Link></li>
														</ul>
													</li>
													<li> <Link to={'#'}>Element</Link>
														<ul>
															<li><Link to={'shortcode-image-box-content'}><i className="ti-image"></i> Image box content</Link></li>
															<li><Link to={'shortcode-counters'}><i className="ti-timer"></i> Counters</Link></li>
															<li><Link to={'shortcode-shop-widgets'}><i className="ti-shopping-cart"></i> Shop Widgets</Link></li>
															<li><Link to={'shortcode-dividers'}><i className="ti-layout-list-post"></i> Dividers</Link></li>
														</ul>
													</li>
													<li> <Link to={'#'}>Element</Link>
														<ul>
															<li><Link to={'shortcode-images-effects'}><i className="ti-layout-media-overlay"></i> Images effects</Link></li>
															<li><Link to={'shortcode-testimonials'}><i className="ti-comments"></i> Testimonials</Link></li>
															<li><Link to={'shortcode-pagination'}><i className="ti-more"></i> Pagination</Link></li>
															<li><Link to={'shortcode-alert-box'}><i className="ti-alert"></i> Alert box</Link></li>
														</ul>
													</li>
													<li> <Link to={'#'}>Element</Link>
														<ul>
															<li><Link to={'shortcode-icon-box'}><i className="ti-layout-media-left-alt"></i> Icon Box</Link></li>
															<li><Link to={'shortcode-list-group'}><i className="ti-list"></i> List group</Link></li>
															<li><Link to={'shortcode-title-separators'}><i className="ti-layout-line-solid"></i> Title Separators</Link></li>
														</ul>
													</li>
												</ul> */}
											</li>
                                            <li>
                                                <Link to={'contact-4'}>CONTACT US</Link>
                                                {/* <ul className="sub-menu right">
                                                    <li><Link to={'/contact-1'}>Contact us 1</Link></li>
                                                    <li><Link to={'/contact-2'}>Contact us 2</Link></li>
                                                    <li><Link to={'/contact-3'}>Contact us 3</Link></li>
                                                    <li><Link to={'/contact-4'}>Contact us 4</Link></li>
                                                </ul> */}
                                            </li>

                                        </ul>
                                        <div className="dlab-social-icon">
                                            <ul>
                                                <li><Link className="site-button circle fa fa-facebook" to={'/#'}></Link></li>
                                                <li><Link className="site-button  circle fa fa-twitter" to={'/#'}></Link></li>
                                                <li><Link className="site-button circle fa fa-linkedin" to={'/#'}></Link></li>
                                                <li><Link className="site-button circle fa fa-instagram" to={'/#'}></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Sticky>

                </header>

            </>
        )
    }
}
export default Header;


