import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from '../layout/header';
import Footer from '../layout/footer6';

const bg = require('../../images/banner/bnharvesting.png')

class EnsInnoSmartEnergyPlatForm extends Component {

    render() {


        return (
            <>
                <Header />
                    <div className="page-content bg-white">
                        <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
                            <div className="container">
                                <div className="dlab-bnr-inr-entry">
                                    <h1 className="text-white">SMART ENERGY PLATFORM</h1>
                                    <div className="breadcrumb-row">
                                        <ul className="list-inline">
                                            <li><Link to="/">HOME</Link></li>
                                            <li><Link to ="ourproduct">OUR PROJECT</Link></li>
                                            <li><Link to ="ensinnoslider">ENERGY INNOVATION</Link></li>
                                            <li>SMART ENERGY PLATFORM</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section-full bg-gray content-inner">
                            <div className="container">
                            <h1 className="title align-items-center tocreate">Smart Energy Platform</h1>
                                <p align="justify">Smart Energy Platform:  An integrated system that leverages advanced technologies and data analytics to optimize the generation, distribution, and consumption of energy. This platform typically incorporates smart devices, sensors, and communication technologies to enable real-time monitoring, control, and management of energy resources. Enserv aims to enhance efficiency, reduce waste, and promote sustainability in energy use. Smart Energy Platforms involve features such as smart grid integration, demand response capabilities, and data-driven insights to empower users in making informed decisions about their energy consumption. These platforms play a crucial role in modernizing energy infrastructure and fostering a more intelligent and sustainable approach to energy management.</p>
                            </div>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                        </div>
                    </div>
                <Footer />
            </>
        )
    }
}
export default EnsInnoSmartEnergyPlatForm;